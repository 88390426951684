import { useToast } from "@chakra-ui/react";
import { DefaultService, UserProfileSchema } from "../generated";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import camelize, { Camelize } from "camelize-ts";
import snakify from "snakify-ts";

export const useUserProfile = () => {
  return useQuery(
    ["userProfile"],
    DefaultService.fakturUsersViewsUserProfileApi,
    {
      select: (data) => {
        const camelizedData = camelize(data);
        return {
          ...camelizedData,
          bankAccounts: data.bank_accounts?.map((bank) => camelize(bank)) || [],
        };
      },
    },
  );
};

export const useUpdateUserProfile = () => {
  const toast = useToast();
  const queryClient = useQueryClient();
  return useMutation(
    ["updateUserProfile"],
    (data: Camelize<UserProfileSchema>) =>
      DefaultService.fakturUsersViewsUpdateUserApi({
        // @ts-ignore
        requestBody: snakify(data),
      }),
    {
      onSuccess: (data) => {
        toast({ title: "Profil oppdatert!", status: "success" });
        queryClient.setQueryData(["userProfile"], data);
      },
    },
  );
};
