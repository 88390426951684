import {
  Container,
  Heading,
  FormControl,
  FormLabel,
  Input,
  Button,
  Checkbox,
  Stack,
  Spinner,
  Alert,
  ButtonGroup,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useFieldArray, useForm } from "react-hook-form";
import * as yup from "yup";
import { useLogout } from "../../hooks/useLogout";
import type { Camelize } from "camelize-ts";
import {
  useUpdateUserProfile,
  useUserProfile,
} from "../../hooks/useUserProfile";
import { AddIcon, MinusIcon } from "@chakra-ui/icons";
import { UserProfileSchema } from "../../generated";
import { useSession } from "../../hooks/useSession";
import { useBrreg } from "../../hooks/useBrreg";
import { useEffect } from "react";
import IssuerModal from "../efaktura/issuer/IssuerModal";
import OcrModal from "../ocr/OcrModal";

const bankAccountSchema = yup.object({
  bankAccountNumber: yup
    .string()
    .matches(
      /^(?:\d{4}\.\d{2}\.\d{5}|\d{11})$/,
      'Bank account number must be in the format "1234.11.12345" or "12341112345"',
    )
    .required(),
  isPrimary: yup.boolean().required(),
});

const schema = yup
  .object({
    companyName: yup.string().required(),
    orgNumber: yup.string().length(9).required(),
    vatRegistered: yup.boolean().required(),
    foretaksregisteret: yup.boolean().required(),
    email: yup.string().email().required(),
    billingAddress1: yup.string().required(),
    billingAddress2: yup.string().optional(),
    billingPostalCode: yup.string().min(3).max(5).required(),
    billingCity: yup.string().required(),
    startInvoiceNumber: yup.number().positive(),
    bankAccounts: yup.array().of(bankAccountSchema).required(),
  })
  .required();

export default function ProfilePage() {
  useSession();
  const userProfileQuery = useUserProfile();
  const updateUserProfile = useUpdateUserProfile();

  const bankAccounts = userProfileQuery.data?.bankAccounts || [];

  const methods = useForm({
    mode: "onSubmit",
    resolver: yupResolver(schema),
    values: {
      ...userProfileQuery.data,
      companyName: userProfileQuery.data?.companyName || "",
      orgNumber: userProfileQuery.data?.orgNumber || "",
      vatRegistered: userProfileQuery.data?.vatRegistered || false,
      foretaksregisteret: userProfileQuery.data?.foretaksregisteret || false,
      billingAddress1: userProfileQuery.data?.billingAddress1 || "",
      billingAddress2: userProfileQuery.data?.billingAddress2 || "",
      billingPostalCode: userProfileQuery.data?.billingPostalCode || "",
      billingCity: userProfileQuery.data?.billingCity || "",
      email: userProfileQuery.data?.email || "",
      startInvoiceNumber:
        userProfileQuery.data?.startInvoiceNumber || undefined,
      bankAccounts:
        bankAccounts.length > 0
          ? bankAccounts
          : [{ bankAccountNumber: "", isPrimary: true }],
    },
  });

  const {
    register,
    handleSubmit,
    formState: { errors: formStateErrors },
    control,
    setValue,
    getValues,
    watch,
    reset,
  } = methods;
  const { fields, append, remove } = useFieldArray({
    control,
    name: "bankAccounts",
    rules: { minLength: 1 },
  });
  watch("bankAccounts");
  watch("orgNumber");
  const bankAccountFields = getValues("bankAccounts");

  const orgNumber = getValues("orgNumber");
  const brreg = useBrreg(orgNumber);
  const logout = useLogout();

  useEffect(() => {
    if (
      orgNumber.length === 9 &&
      userProfileQuery.data?.orgNumber !== orgNumber
    ) {
      const values = getValues();
      reset({
        ...values,
        companyName: values?.companyName || brreg.data?.navn,
        billingAddress1:
          values?.billingAddress1 ||
          brreg.data?.beliggenhetsadresse.adresse?.[0],
        billingCity:
          values?.billingCity || brreg.data?.beliggenhetsadresse.poststed,
        billingPostalCode:
          values?.billingPostalCode ||
          brreg.data?.beliggenhetsadresse.postnummer,
      });
    }
  }, [
    orgNumber,
    brreg.data,
    userProfileQuery.data?.orgNumber,
    getValues,
    reset,
  ]);

  const onSubmit = (data: Camelize<UserProfileSchema>) => {
    return updateUserProfile.mutate(data);
  };

  if (userProfileQuery.isLoading) {
    return (
      <Container display="flex" justifyContent="center">
        <Spinner />
      </Container>
    );
  }

  return (
    <Stack display="flex">
      <Heading as="h1" mb={4}>
        Profil
      </Heading>
      {!userProfileQuery.data?.isProfileCompleted && (
        <Alert>Fullfør profilen før du kan lage fakturaer</Alert>
      )}
      <Stack direction="row">
        <IssuerModal />
        <OcrModal />
      </Stack>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack minW={350} pt={4}>
          <FormControl isInvalid={Boolean(formStateErrors["orgNumber"])}>
            <FormLabel>Organisasjonsnummer</FormLabel>
            <Stack direction="row">
              <Input {...register("orgNumber")} placeholder="987654321" />
            </Stack>
          </FormControl>
          <FormControl isInvalid={Boolean(formStateErrors["companyName"])}>
            <FormLabel>Bedriftsnavn</FormLabel>
            <Input {...register("companyName")} placeholder="Faktura AS" />
          </FormControl>
          <FormControl isInvalid={Boolean(formStateErrors["vatRegistered"])}>
            <Stack direction="row">
              <FormLabel width={150}>MVA-registrert</FormLabel>
              <Checkbox
                {...register("vatRegistered")}
                size="lg"
                height="24px"
              />
            </Stack>
          </FormControl>
          <FormControl
            isInvalid={Boolean(formStateErrors["foretaksregisteret"])}
          >
            <Stack direction="row">
              <FormLabel width={150}>Foretaksregisteret</FormLabel>
              <Checkbox
                {...register("foretaksregisteret")}
                size="lg"
                height="24px"
              />
            </Stack>
          </FormControl>
          <FormControl isInvalid={Boolean(formStateErrors["email"])}>
            <FormLabel>E-post</FormLabel>
            <Input {...register("email")} placeholder="accounting@faktura" />
          </FormControl>
          <FormControl isInvalid={Boolean(formStateErrors["billingAddress1"])}>
            <FormLabel>Adresse</FormLabel>
            <Input
              {...register("billingAddress1")}
              placeholder="Slottsplassen 1"
            />
          </FormControl>
          <FormControl isInvalid={Boolean(formStateErrors["billingAddress2"])}>
            <FormLabel>Adresselinje 2 (valgfritt)</FormLabel>
            <Input {...register("billingAddress2")} placeholder="" />
          </FormControl>
          <FormControl
            isInvalid={Boolean(formStateErrors["billingPostalCode"])}
          >
            <FormLabel>Postkode</FormLabel>
            <Input {...register("billingPostalCode")} placeholder="0001" />
          </FormControl>
          <FormControl isInvalid={Boolean(formStateErrors["billingCity"])}>
            <FormLabel>By</FormLabel>
            <Input {...register("billingCity")} placeholder="Oslo" />
          </FormControl>
          <FormControl
            isInvalid={Boolean(formStateErrors["startInvoiceNumber"])}
          >
            <FormLabel>Første fakturanummer (kan kun settes en gang)</FormLabel>
            <Input
              type="number"
              {...register("startInvoiceNumber")}
              min={1}
              isDisabled={Boolean(userProfileQuery.data?.hasCreatedInvoice)}
            />
          </FormControl>
          {fields.map((field, i) => {
            const bankAccount = bankAccountFields?.[i];
            return (
              <Stack key={field.id} spacing="2" direction="row">
                <FormControl
                  isInvalid={Boolean(
                    formStateErrors?.bankAccounts?.[i]?.bankAccountNumber
                      ?.message,
                  )}
                >
                  <FormLabel>Kontonummer</FormLabel>
                  <Input
                    {...register(`bankAccounts.${i}.bankAccountNumber`)}
                    placeholder="1111.11.11111"
                  />
                </FormControl>
                <FormControl
                  isInvalid={Boolean(
                    formStateErrors?.bankAccounts?.[i]?.isPrimary?.message,
                  )}
                  onClick={() => {
                    fields.forEach((_, fieldIndex) => {
                      if (i !== fieldIndex) {
                        setValue(`bankAccounts.${fieldIndex}.isPrimary`, false);
                      }
                    });
                  }}
                >
                  {i === 0 && <FormLabel>Primærkonto</FormLabel>}
                  <Checkbox
                    {...register(`bankAccounts.${i}.isPrimary`)}
                    size="lg"
                    mt={i !== 0 ? 8 : 0}
                    isChecked={bankAccount.isPrimary}
                  />
                </FormControl>
                <Button
                  onClick={() => {
                    remove(i);
                  }}
                >
                  <MinusIcon />
                </Button>
              </Stack>
            );
          })}
          <Button
            onClick={() => {
              append({ bankAccountNumber: "", isPrimary: false });
            }}
          >
            <AddIcon />
          </Button>
        </Stack>
        <Stack direction="row" py={8}>
          <ButtonGroup>
            <Button type="submit">Oppdater</Button>
            <Button onClick={() => logout()} colorScheme="red">
              Logg ut
            </Button>
          </ButtonGroup>
        </Stack>
      </form>
    </Stack>
  );
}
