import {
  Heading,
  Stat,
  StatLabel,
  StatNumber,
  Button,
  Flex,
  Link,
  TableContainer,
  Table,
  Thead,
  Tr,
  Td,
  Tbody,
  Stack,
  IconButton,
} from "@chakra-ui/react";
import { Link as ReactRouterLink } from "react-router-dom";
import { useSession } from "../../../hooks/useSession";
import { useCustomerGroupList } from "../../../hooks/useCustomerGroup";
import { EditIcon } from "@chakra-ui/icons";

export default function CustomerGroupPage() {
  useSession();

  const { data, isLoading } = useCustomerGroupList();

  return (
    <Stack>
      <Heading as="h1" mb={4}>
        Kundegruppeliste
      </Heading>
      <Stack direction="row">
        <Link as={ReactRouterLink} to="/customers/groups/create/">
          <Button variant="solid" colorScheme="blue">
            Lag ny gruppe
          </Button>
        </Link>
      </Stack>
      <Flex alignItems="center">
        <Stat my={4}>
          <StatLabel>Antall kundegrupper</StatLabel>
          <StatNumber>{data?.length || 0}</StatNumber>
        </Stat>
      </Flex>
      <TableContainer>
        <Table variant="striped">
          <Thead>
            <Tr>
              <Td>Navn</Td>
              <Td>Antall</Td>
              <Td>Handlinger</Td>
            </Tr>
          </Thead>
          <Tbody>
            {!isLoading &&
              data?.map((group) => (
                <Tr key={group.uuid}>
                  <Td>{group.groupName}</Td>
                  <Td>{group.customers.length}</Td>
                  <Td>
                    <Link
                      as={ReactRouterLink}
                      to={`/customers/groups/${group.uuid}/edit/`}
                    >
                      <IconButton
                        size="lg"
                        colorScheme="blue"
                        variant="ghost"
                        icon={<EditIcon />}
                        aria-label="Endre"
                      />
                    </Link>
                  </Td>
                </Tr>
              ))}
          </Tbody>
        </Table>
      </TableContainer>
    </Stack>
  );
}
