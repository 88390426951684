import { Navbar } from "./components/navbar";
import { Footer } from "./components/footer";
import { Container } from "@chakra-ui/react";
import { Breadcrumbs } from "./components/breadcrumbs";
import { Outlet } from "react-router-dom";

export default function RootLayout({
  withBreadcrumbs = true,
}: {
  withBreadcrumbs?: boolean;
}) {
  return (
    <Container minH="100vh" minW="100vw" p={0}>
      <Navbar />
      <Container maxW="container.lg" paddingBottom={150}>
        {withBreadcrumbs && <Breadcrumbs />}
        <Container maxW="100%" py={8}>
          <Outlet />
        </Container>
      </Container>
      <Footer />
    </Container>
  );
}
