import { Box, Heading } from "@chakra-ui/react";

import { InvoiceTemplateForm } from "../InvoiceTemplateForm";

export default function InvoiceTemplateCreatePage() {
  return (
    <Box>
      <Heading as="h1" mb={4}>
        Lag fakturamal
      </Heading>
      <InvoiceTemplateForm />
    </Box>
  );
}
