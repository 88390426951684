import {
  Alert,
  Badge,
  Box,
  Button,
  Card,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Select,
  Stack,
} from "@chakra-ui/react";
import { AddIcon, DeleteIcon } from "@chakra-ui/icons";
import { useCallback } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { CustomerForm } from "../../customers/CustomerForm";
import { Camelize } from "camelize-ts";

import {
  useInvoiceCreate,
  useInvoiceCreateFromTemplate,
} from "../../../hooks/useInvoiceCreate";
import {
  CustomerSchema,
  InvoiceBodySchema,
  InvoiceDeliveryType,
} from "../../../generated";
import { SearchCustomers } from "./SearchCustomers";
import { useInvoiceTemplateList } from "../../../hooks/useInvoiceTemplateList";

const invoiceLineSchema = yup.object({
  invoicedQuantity: yup.number().integer().required(),
  description: yup.string().required(),
  netUnitAmount: yup.number().required(),
  vatRatePercentage: yup.number().integer().required(),
});
const schema = yup
  .object({
    deliveryType: yup
      .string()
      .oneOf(Object.values(InvoiceDeliveryType))
      .ensure(),
    groupUuid: yup.string().nullable(),
    customerNumbers: yup.array().of(yup.number().positive().required()),
    templateId: yup.number(),
    issueDate: yup.string().required(),
    dueDays: yup.number().positive().integer().required(),
    invoiceLines: yup.array().of(invoiceLineSchema).required(),
  })
  .required();

const InvoiceForm = () => {
  const invoiceTemplateListQuery = useInvoiceTemplateList();
  const invoiceCreateMutation = useInvoiceCreate();
  const invoiceCreateFromTemplateMutation = useInvoiceCreateFromTemplate();

  const dateToday = new Date().toISOString().substring(0, 10);

  const {
    register,
    handleSubmit,
    formState: { errors: formStateErrors },
    watch,
    setValue,
    getValues,
    control,
    setError,
    reset,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      deliveryType: InvoiceDeliveryType.NONE,
      groupUuid: null,
      customerNumbers: [],
      templateId: 0,
      issueDate: dateToday,
      dueDays: 14,
      invoiceLines: [
        {
          invoicedQuantity: 1,
          description: "",
          netUnitAmount: undefined,
          vatRatePercentage: 25,
        },
      ],
    },
  });
  const { fields, append, remove, update } = useFieldArray({
    control,
    name: "invoiceLines",
  });

  watch("invoiceLines");
  watch("customerNumbers");
  watch("groupUuid");

  const customerNumbers = getValues("customerNumbers") || [];
  const groupUuid = getValues("groupUuid");
  const setCustomer = useCallback(
    (data: Camelize<CustomerSchema>) => {
      if (data.customerNumber) {
        setValue("customerNumbers", [
          ...(getValues("customerNumbers") || []),
          data.customerNumber,
        ]);
      }
    },
    [getValues, setValue],
  );

  const onSubmit = (
    data: { templateId?: number } & Camelize<InvoiceBodySchema>,
  ) => {
    if (!data.groupUuid && (data.customerNumbers || []).length === 0) {
      setError("customerNumbers", {
        type: "custom",
        message: "Missing customerNumbers",
      });
      setError("groupUuid", { type: "custom", message: "Missing groupUuid" });
      return;
    }
    if (data.templateId) {
      return invoiceCreateFromTemplateMutation.mutate({
        templateId: 4,
        data: {
          issueDate: data.issueDate,
          customerNumbers: data.customerNumbers,
          groupUuid: data.groupUuid,
        },
      });
    }
    return invoiceCreateMutation.mutate({ ...data });
  };

  const customersAreSet = customerNumbers.length > 0 || groupUuid;
  const templateSet = Boolean((getValues("templateId") || 0) > 0);

  return (
    <>
      <Box mt={10} minW={350}>
        <Stack display="flex" direction="row" gap={8} wrap="wrap">
          <SearchCustomers
            customerNumbers={customerNumbers}
            isInvalid={Boolean(formStateErrors["customerNumbers"])}
            setCustomers={(data: number[]) => setValue("customerNumbers", data)}
            groupUuid={groupUuid}
            setGroup={(data?: string | null) => setValue("groupUuid", data)}
          />
          <Box minW={350} flex={1}>
            <Card minH={300} p={4}>
              <Heading size="md">Ny kunde</Heading>
              <CustomerForm customer={null} setCustomer={setCustomer} />
            </Card>
          </Box>
        </Stack>
      </Box>
      <Stack direction="row" mt={8}>
        {customerNumbers.map((customerNumber) => (
          <Badge
            key={customerNumber}
            fontSize={18}
            p={2}
            borderRadius={4}
            backgroundColor="blue.200"
          >
            Kunde: {customerNumber}
          </Badge>
        ))}
        {groupUuid && (
          <Badge
            fontSize={18}
            p={2}
            borderRadius={4}
            backgroundColor="green.200"
          >
            Kundegruppe: {groupUuid}
          </Badge>
        )}
      </Stack>
      {!customersAreSet && <Alert>Legg til kunde først</Alert>}
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{ opacity: !customersAreSet ? 0.5 : 1 }}
      >
        <Input {...register("customerNumbers")} type="hidden" />
        <Input {...register("groupUuid")} type="hidden" />
        <Stack mt={8}>
          <FormControl
            isInvalid={Boolean(formStateErrors["templateId"])}
            isDisabled={invoiceTemplateListQuery.data?.length === 0}
          >
            <FormLabel>Fakturamal</FormLabel>
            <Select
              {...register("templateId")}
              onChange={(e) => {
                const template = invoiceTemplateListQuery.data?.find(
                  (x) => x.id.toString() === e.target.value,
                );
                reset({
                  customerNumbers: customerNumbers,
                  groupUuid: groupUuid,
                  deliveryType: template?.deliveryType,
                  dueDays: template?.dueDays,
                  invoiceLines: template?.lines.map((line) => ({
                    invoicedQuantity: line.invoicedQuantity,
                    description: line.description,
                    netUnitAmount: +line.netUnitAmount,
                    vatRatePercentage: line.vatRatePercentage,
                  })) || [{}],
                });
              }}
            >
              <option key={0} value={0}>
                {">"} Velg fakturamal
              </option>
              {invoiceTemplateListQuery.data?.map((template) => (
                <option key={template.id} value={template.id}>
                  {template.name}
                </option>
              ))}
            </Select>
          </FormControl>
          <FormLabel>Fakturanummer</FormLabel>
          <Input isDisabled={true} placeholder="1000" />
          <FormControl isInvalid={Boolean(formStateErrors["issueDate"])}>
            <FormLabel>Fakturadato</FormLabel>
            <Input {...register("issueDate")} type="date" />
          </FormControl>
          <FormControl
            isInvalid={Boolean(formStateErrors["deliveryType"])}
            isDisabled={templateSet}
          >
            <FormLabel>Utsendingsform</FormLabel>
            <Select {...register("deliveryType")}>
              {[
                ["Ingen", "NONE"],
                ["E-post", "EMAIL"],
              ].map(([key, val]) => (
                <option key={key} value={val}>
                  {key}
                </option>
              ))}
            </Select>
          </FormControl>
          <FormControl
            isInvalid={Boolean(formStateErrors["dueDays"])}
            isDisabled={templateSet}
          >
            <FormLabel>Forfall</FormLabel>
            <Input {...register("dueDays")} placeholder="14" />
          </FormControl>
        </Stack>
        <Stack margin={4}>
          {fields.map((field, i) => {
            const invoiceLine = getValues("invoiceLines")?.[i];
            const errorLine = formStateErrors.invoiceLines?.[i];
            return (
              <Stack
                key={field.id}
                direction="row"
                wrap={["wrap", "wrap", "inherit"]}
              >
                <FormControl
                  isInvalid={Boolean(errorLine?.invoicedQuantity)}
                  minWidth={100}
                  maxWidth={100}
                  isDisabled={templateSet}
                >
                  <FormLabel>Antall</FormLabel>
                  <NumberInput
                    defaultValue={1}
                    min={1}
                    onChange={(valueString) => {
                      update(i, {
                        ...invoiceLine,
                        invoicedQuantity: parseInt(valueString, 10),
                      });
                    }}
                  >
                    <NumberInputField
                      {...register(`invoiceLines.${i}.invoicedQuantity`)}
                      placeholder="Antall"
                    />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                </FormControl>
                <FormControl
                  isInvalid={Boolean(errorLine?.description)}
                  minWidth={300}
                  maxWidth={450}
                  isDisabled={templateSet}
                >
                  <FormLabel>Beskrivelse</FormLabel>
                  <Input
                    {...register(`invoiceLines.${i}.description`)}
                    placeholder="Beskrivelse"
                  />
                </FormControl>
                <FormControl
                  isInvalid={Boolean(errorLine?.netUnitAmount)}
                  minWidth={150}
                  maxWidth={150}
                  isDisabled={templateSet}
                >
                  <FormLabel>Enhetspris</FormLabel>
                  <NumberInput>
                    <NumberInputField
                      {...register(`invoiceLines.${i}.netUnitAmount`)}
                      placeholder="100"
                    />
                  </NumberInput>
                </FormControl>
                <FormControl
                  isInvalid={Boolean(errorLine?.vatRatePercentage)}
                  minWidth={75}
                  maxWidth={100}
                  isDisabled={templateSet}
                >
                  <FormLabel>MVA</FormLabel>
                  <Select {...register(`invoiceLines.${i}.vatRatePercentage`)}>
                    <option value={0}>0%</option>
                    <option value={15}>15%</option>
                    <option value={25}>25%</option>
                  </Select>
                </FormControl>
                <FormControl
                  minWidth={100}
                  maxWidth={100}
                  isDisabled={templateSet}
                >
                  <FormLabel>Sum</FormLabel>
                  <Input
                    isDisabled={true}
                    value={(
                      (invoiceLine?.invoicedQuantity || 0) *
                        (invoiceLine?.netUnitAmount || 0) *
                        (1 + (invoiceLine?.vatRatePercentage || 0) / 100) ?? 0
                    ).toFixed(2)}
                  />
                </FormControl>
                <Flex alignItems="flex-end">
                  <Button
                    onClick={() => {
                      remove(i);
                    }}
                    isDisabled={templateSet}
                  >
                    <DeleteIcon />
                  </Button>
                </Flex>
              </Stack>
            );
          })}
          <Stack>
            <Button
              leftIcon={<AddIcon />}
              onClick={() =>
                append({
                  invoicedQuantity: 1,
                  description: "",
                  // @ts-ignore
                  netUnitAmount: undefined,
                  vatRatePercentage: 25,
                })
              }
              variant="outline"
              isDisabled={templateSet}
            >
              Legg til linje
            </Button>
          </Stack>
          <Button type="submit" isDisabled={!customersAreSet}>
            Lagre
          </Button>
        </Stack>
      </form>
    </>
  );
};

export default function InvoiceCreatePage() {
  return (
    <Box>
      <Heading as="h1" mb={4}>
        Lag faktura
      </Heading>
      <InvoiceForm />
    </Box>
  );
}
