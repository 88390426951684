import { Link as ReactRouterLink } from "react-router-dom";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Container,
  Stack,
} from "@chakra-ui/react";
import { useLocation } from "react-router-dom";

const breadcrumbMapping: Record<string, string> = {
  overview: "Oversikt",
  invoices: "Faktura",
  create: "Ny",
  profile: "Profil",
  customers: "Kundeliste",
  groups: "Grupper",
  templates: "Mal",
  edit: "Endre",
  privacy: "Personvernserklæring",
  auth: "Autentisering",
  login: "Innlogging",
  "magic-link": "Magisk lenke",
  signup: "Registrer",
};

export const Breadcrumbs = () => {
  const location = useLocation();
  const subPaths = location.pathname.split("/").filter(Boolean);

  if (subPaths.length === 0) {
    return null;
  }

  return (
    <Container maxW="container.lg" px={8} paddingTop={2}>
      <Stack direction="row">
        <span>{">"}</span>
        <Breadcrumb>
          {subPaths.map((subPath, i) => (
            <BreadcrumbItem key={subPath}>
              <BreadcrumbLink
                as={ReactRouterLink}
                to={"/" + subPaths.slice(0, i + 1).join("/")}
              >
                {breadcrumbMapping[subPath] || subPath.split("-")[0]}
              </BreadcrumbLink>
            </BreadcrumbItem>
          ))}
        </Breadcrumb>
      </Stack>
    </Container>
  );
};
