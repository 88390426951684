import { Search2Icon } from "@chakra-ui/icons";
import { Button, Heading, Link, Stack } from "@chakra-ui/react";
import { Link as ReactRouterLink } from "react-router-dom";

export const NotFound = () => {
  return (
    <Stack align="center">
      <Search2Icon fontSize={48} />
      <Heading size="xl">404 - Finner ikke siden</Heading>
      <Link as={ReactRouterLink} to={"/invoices/"}>
        <Button variant="solid" mt={8}>
          Tilbake
        </Button>
      </Link>
    </Stack>
  );
};
