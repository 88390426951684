import {
  AddIcon,
  AtSignIcon,
  AttachmentIcon,
  CheckCircleIcon,
  CheckIcon,
  ChevronDownIcon,
  CloseIcon,
  EmailIcon,
  HamburgerIcon,
  LinkIcon,
  RepeatIcon,
  TimeIcon,
  ViewIcon,
} from "@chakra-ui/icons";
import {
  Heading,
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  StatArrow,
  Button,
  Flex,
  Link,
  Stack,
  Text,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Tr,
  Td,
  Thead,
  Badge,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  Alert,
  AlertIcon,
  ButtonGroup,
} from "@chakra-ui/react";
import { useSession } from "../../hooks/useSession";
import { useInvoiceList } from "../../hooks/useInvoiceList";
import { useInvoiceSend } from "../../hooks/useInvoiceSend";
import { useInvoicesQueue } from "../../hooks/useInvoicesQueue";
import { useInvoiceUpdate } from "../../hooks/useInvoiceUpdate";
import { InvoiceDeliveryStatus, InvoiceStatus, OpenAPI } from "../../generated";
import { getColorScheme } from "../../utils/colorScheme";
import { Link as ReactRouterLink } from "react-router-dom";

export default function InvoicePage() {
  const sessionQuery = useSession();

  const { data, isLoading, hasNextPage, fetchNextPage } = useInvoiceList();

  const invoicesQueueMutation = useInvoicesQueue();
  const invoiceSendMutation = useInvoiceSend();
  const invoiceUpdateMutation = useInvoiceUpdate();

  const flattenedData = data?.pages.flatMap((page) => page);

  return (
    <Stack>
      <Heading as="h1" mb={4}>
        Fakturaer
      </Heading>
      {!isLoading && !sessionQuery.data?.isProfileCompleted && (
        <Alert>
          <Stack direction="row" alignItems="center">
            <Text>Fullfør profilen før du kan lage fakturaer</Text>
            <Link as={ReactRouterLink} to="/profile/">
              <Button>Gå til profil</Button>
            </Link>
          </Stack>
        </Alert>
      )}
      {flattenedData?.find(
        (invoice) => invoice.status === InvoiceStatus.DRAFT,
      ) && (
        <Alert status="info">
          <AlertIcon />
          Du har fakturaer som utkast. Klikk &quot;Registrer og send
          faktura&quot; for å generere fakturanummer og legge alle fakturaene
          til utsending.
        </Alert>
      )}
      <Stack direction="row" flexWrap="wrap">
        <ButtonGroup>
          <Link as={ReactRouterLink} to="/invoices/create/">
            <Button
              variant="solid"
              colorScheme="blue"
              rightIcon={<AddIcon />}
              isDisabled={!sessionQuery.data?.isProfileCompleted}
            >
              Lag ny
            </Button>
          </Link>
          <Link as={ReactRouterLink} to="/invoices/templates/">
            <Button
              variant="solid"
              colorScheme="purple"
              rightIcon={<AttachmentIcon />}
              isDisabled={!sessionQuery.data?.isProfileCompleted}
            >
              Maler
            </Button>
          </Link>
          <Link as={ReactRouterLink} to="/invoices/create">
            <Button
              variant="solid"
              isDisabled={true}
              rightIcon={<RepeatIcon />}
            >
              Fast oppdrag
            </Button>
          </Link>
          <Link as={ReactRouterLink} to="/invoices/create">
            <Button variant="solid" isDisabled={true}>
              Eksporter
            </Button>
          </Link>
          <Link as={ReactRouterLink} to="/invoices/create">
            <Button variant="solid" isDisabled={true} rightIcon={<LinkIcon />}>
              Del oversikt
            </Button>
          </Link>
        </ButtonGroup>
      </Stack>
      <Flex alignItems="center" wrap="wrap">
        <Stat my={4}>
          <StatLabel>Antall sendte fakturaer</StatLabel>
          <StatNumber>{flattenedData?.length}</StatNumber>
          <StatHelpText>
            <StatArrow type="increase" />
            {flattenedData?.[flattenedData?.length - 1]?.issueDate} -{" "}
            {flattenedData?.[0]?.issueDate}
          </StatHelpText>
        </Stat>
        <Stack direction="row" spacing={4} wrap="wrap">
          <Button
            onClick={() => invoicesQueueMutation.mutate(undefined)}
            isDisabled={
              !flattenedData?.some((x) => x.status === InvoiceStatus.DRAFT)
            }
            rightIcon={
              invoicesQueueMutation.isLoading ? <Spinner /> : <TimeIcon />
            }
          >
            Registrer og send faktura
          </Button>
          {sessionQuery.data?.isStaff && (
            <Button
              onClick={() => invoiceSendMutation.mutate()}
              isDisabled={
                !flattenedData?.some(
                  (x) => x.status === InvoiceStatus.REGISTERED,
                )
              }
              rightIcon={
                invoiceSendMutation.isLoading ? <Spinner /> : <EmailIcon />
              }
            >
              Send fakturaer
            </Button>
          )}
        </Stack>
      </Flex>
      <TableContainer>
        <Table variant="striped">
          <Thead>
            <Tr>
              <Td>Fakturanummer</Td>
              <Td>Kunde</Td>
              <Td>Fakturadato</Td>
              <Td>Status</Td>
              <Td>Handlinger</Td>
            </Tr>
          </Thead>
          <Tbody>
            {!isLoading &&
              flattenedData?.map((invoice) => (
                <Tr key={invoice.uuid}>
                  <Td>{invoice.invoiceNumber ?? <AtSignIcon />}</Td>
                  <Td>{invoice.customerName}</Td>
                  <Td>{invoice.issueDate}</Td>
                  <Td>
                    <Badge colorScheme={getColorScheme(invoice.status)}>
                      <Stack direction="row" alignItems="center">
                        <Text fontSize={14}>{invoice.status}</Text>
                        {invoice.status === InvoiceStatus.PAID ? (
                          <CheckIcon />
                        ) : invoice.deliveryStatus ===
                            InvoiceDeliveryStatus.SENT &&
                          new Date(invoice.dueDate) < new Date() ? (
                          <CloseIcon />
                        ) : invoice.deliveryStatus ===
                          InvoiceDeliveryStatus.SENT ? (
                          <EmailIcon />
                        ) : invoice.deliveryStatus ===
                            InvoiceDeliveryStatus.QUEUED ||
                          invoice.status === InvoiceStatus.REGISTERED ? (
                          <TimeIcon />
                        ) : (
                          ""
                        )}
                      </Stack>
                    </Badge>
                  </Td>
                  <Td>
                    <Stack direction="row">
                      <Link
                        as={ReactRouterLink}
                        to={`/invoices/${
                          invoice.invoiceNumber || invoice.uuid
                        }/`}
                      >
                        <IconButton
                          colorScheme="blackAlpha"
                          icon={<ViewIcon />}
                          aria-label="Se detaljer"
                        />
                      </Link>
                      <Menu>
                        <MenuButton
                          as={IconButton}
                          icon={<HamburgerIcon />}
                          colorScheme="blue"
                        />
                        <MenuList>
                          <MenuItem>
                            <Link
                              as={ReactRouterLink}
                              to={`/invoices/${
                                invoice.invoiceNumber || invoice.uuid
                              }/`}
                            >
                              Se detaljer
                            </Link>
                          </MenuItem>
                          <MenuItem>
                            <Link
                              href={`${OpenAPI.BASE}/invoices/${invoice.uuid}/pdf/`}
                              target="_blank"
                            >
                              Vis PDF
                            </Link>
                          </MenuItem>
                          <MenuItem>
                            {invoice.status != InvoiceStatus.PAID && (
                              <Link
                                onClick={() =>
                                  invoiceUpdateMutation.mutate({
                                    invoiceUuid: invoice.uuid,
                                    requestBody: { status: InvoiceStatus.PAID },
                                  })
                                }
                              >
                                Sett til betalt <CheckCircleIcon />
                              </Link>
                            )}
                          </MenuItem>
                        </MenuList>
                      </Menu>
                    </Stack>
                  </Td>
                </Tr>
              ))}
          </Tbody>
        </Table>
      </TableContainer>
      {isLoading && (
        <Flex flex={1} justifyContent="center">
          <Spinner />
        </Flex>
      )}
      <Button
        rightIcon={<ChevronDownIcon />}
        isDisabled={!hasNextPage}
        onClick={() => fetchNextPage()}
      >
        Hent flere
      </Button>
    </Stack>
  );
}
