import { Container, Divider, Flex, Heading, Link } from "@chakra-ui/react";

export const Footer = () => {
  const isLoggedIn = true;
  return (
    <Container
      maxW="100%"
      p={0}
      height={150}
      position="absolute"
      bottom={0}
      backgroundColor="blackAlpha.200"
    >
      <Divider />
      <Container maxW="container.lg" px={8}>
        <Flex justifyContent="space-between" alignItems="center" height="4rem">
          <Link href={isLoggedIn ? "/invoices" : "/"}>
            <Heading size="sm">FAKTUR.NO - 2023</Heading>
          </Link>
        </Flex>
        <Link href="mailto:support@faktur.no">support@faktur.no</Link>
      </Container>
    </Container>
  );
};
