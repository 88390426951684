export const getFormattedDate = (d: string): string => {
  const parsedDate = Date.parse(d);
  const norwegianDateFormat = new Intl.DateTimeFormat("no-NO", {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: false,
  });
  return norwegianDateFormat.format(parsedDate);
};
