import { useToast } from "@chakra-ui/react";
import { DefaultService } from "../generated";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export const useInvoiceUpdate = () => {
  const toast = useToast();
  const queryClient = useQueryClient();
  return useMutation(
    ["invoiceUpdate"],
    DefaultService.fakturInvoicesViewsInvoiceUpdate,
    {
      onSuccess: () => {
        toast({ title: "Faktura oppdatert!", status: "success" });
        queryClient.invalidateQueries(["invoiceDetail"]);
        queryClient.invalidateQueries(["invoiceList"]);
      },
    },
  );
};
