/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum InvoiceDeliveryType {
  NONE = "NONE",
  EHF = "EHF",
  EFAKTURA = "EFAKTURA",
  EMAIL = "EMAIL",
}
