import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Spinner,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { useOutletContext, useSearchParams } from "react-router-dom";
import { useLoginVerify } from "../../../hooks/useLoginVerify";
import { useSession } from "../../../hooks/useSession";

const schema = yup
  .object({
    registrationToken: yup.string().min(6).max(6).uppercase().required(),
  })
  .required();

export default function MagicLinkPage() {
  useSession({ redirectWhenAuthorized: true, redirectWhenUnauthorized: false });
  const [email] = useOutletContext<[string]>();
  const {
    register,
    handleSubmit,
    formState: { errors: formStateErrors },
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(schema),
  });

  const { mutate, isLoading, isSuccess } = useLoginVerify();
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token") || undefined;

  useEffect(() => {
    if (token) {
      mutate({ token });
    }
  }, [token, mutate]);

  const onSubmit = async (data: { registrationToken: string }) => {
    return await mutate({ email, registrationToken: data.registrationToken });
  };

  return (
    <Box textAlign="center" p={10}>
      <Heading as="h1" mb={4}>
        Sjekk din e-post for en kode
      </Heading>
      <p>
        Vi har sent en kode til din epost {email}. Denne koden utløper etter
        kort tid.
      </p>
      {(isLoading || isSuccess) && <Spinner />}
      {!isLoading && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormControl
            isInvalid={Boolean(formStateErrors["registrationToken"])}
          >
            <FormLabel>Kode</FormLabel>
            <Input
              {...register("registrationToken")}
              style={{ textTransform: "uppercase" }}
              placeholder=""
            />
          </FormControl>
          <Box mt={10} minW={350}>
            {isLoading && <Spinner />}
            {!isLoading && <Button type="submit">Logg inn</Button>}
          </Box>
        </form>
      )}
    </Box>
  );
}
