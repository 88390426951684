import { useNavigate } from "react-router-dom";
import { DefaultService } from "../generated";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export const useLogout = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { mutate } = useMutation(
    ["logout"],
    DefaultService.fakturUsersViewsLogoutApi,
    {
      onSettled: () => {
        queryClient.removeQueries(["session"]);
        queryClient.invalidateQueries(["session"]);
      },
      onSuccess: () => {
        navigate("/");
      },
    },
  );
  return mutate;
};
