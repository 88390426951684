import { ChakraProvider, useToast } from "@chakra-ui/react";
import { extendTheme, type ThemeConfig } from "@chakra-ui/react";
import {
  MutationCache,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import "@fontsource/space-grotesk";
import { useState } from "react";
import { ApiError, OpenAPI } from "./generated";
import {
  createBrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  RouterProvider,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import App from "./App";
import SignupPage from "./pages/auth/signup/page";
import LoginPage from "./pages/auth/login/page";
import MagicLinkPage from "./pages/auth/magic-link/page";
import CustomerGroupCreatePage from "./pages/customers/groups/create/page";
import CustomerPage from "./pages/customers/page";
import CustomerGroupPage from "./pages/customers/groups/page";
import InvoiceCreatePage from "./pages/invoices/create/page";
import ProfilePage from "./pages/profile/page";
import RootLayout from "./layout";
import InvoiceDetailPage from "./pages/invoices/detail/page";
import InvoicePage from "./pages/invoices/page";
import InvoiceTemplateCreatePage from "./pages/invoices/templates/create/page";
import InvoiceTemplatesPage from "./pages/invoices/templates/page";
import InvoiceTemplateEditPage from "./pages/invoices/templates/edit/page";
import PrivacyPage from "./pages/privacy";
import * as Sentry from "@sentry/react";
import React from "react";
import AuthWrapper from "./pages/auth/wrapper";
import Unsubscribe from "./pages/emails/unsubscribe/page";
import CustomerCreatePage from "./pages/customers/create/page";
import CustomerEditPage from "./pages/customers/edit/page";
import CustomerGroupEditPage from "./pages/customers/groups/edit/page";

Sentry.init({
  dsn: "https://3e9edcebd5fd7709fd0ded09f39519f5@o4505953134051328.ingest.sentry.io/4505953138835456",
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", /^https:\/\/api\.faktur\.no\/api/],
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      ),
    }),
    //new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: process.env.NODE_ENV === "production" ? 0.1 : 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  //replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  //replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const router = sentryCreateBrowserRouter([
  {
    path: "/",
    element: <RootLayout />,
    errorElement: (
      <Sentry.ErrorBoundary fallback={<p>An error has occurred</p>} />
    ),
    children: [
      {
        index: true,
        element: <App />,
      },
      {
        path: "profile/",
        element: <ProfilePage />,
      },
      {
        path: "emails/unsubscribe/",
        element: <Unsubscribe />,
      },
      {
        path: "privacy/",
        element: <PrivacyPage />,
      },
      {
        path: "invoices/",
        children: [
          {
            index: true,
            element: <InvoicePage />,
          },
          {
            path: "create/",
            element: <InvoiceCreatePage />,
          },
          {
            path: "templates/",
            children: [
              {
                index: true,
                element: <InvoiceTemplatesPage />,
              },
              {
                path: "create/",
                element: <InvoiceTemplateCreatePage />,
              },
              {
                path: ":id/edit/",
                element: <InvoiceTemplateEditPage />,
              },
              {
                path: ":id/",
                element: <InvoiceTemplatesPage />,
              },
            ],
          },
          {
            path: ":id/",
            element: <InvoiceDetailPage />,
          },
        ],
      },
      {
        path: "auth/",
        element: <AuthWrapper />,
        children: [
          {
            path: "signup/",
            element: <SignupPage />,
          },
          {
            path: "login/",
            element: <LoginPage />,
          },
          {
            path: "magic-link/",
            element: <MagicLinkPage />,
          },
        ],
      },
      {
        path: "customers/",
        children: [
          {
            element: <CustomerPage />,
            index: true,
          },
          {
            path: "create/",
            element: <CustomerCreatePage />,
          },
          {
            path: ":id/",
            element: <CustomerEditPage />,
          },
          {
            path: ":id/edit/",
            element: <CustomerEditPage />,
          },
          {
            path: "groups/",
            children: [
              {
                element: <CustomerGroupPage />,
                index: true,
              },
              {
                path: "create/",
                element: <CustomerGroupCreatePage />,
              },
              {
                path: ":id/edit/",
                element: <CustomerGroupEditPage />,
              },
            ],
          },
        ],
      },
    ],
  },
]);

OpenAPI.BASE =
  process.env.NODE_ENV === "development"
    ? "http://localhost:8000"
    : "https://api.faktur.no";

OpenAPI.WITH_CREDENTIALS = true;

const config: ThemeConfig = {
  initialColorMode: "light",
  useSystemColorMode: false,
};

const theme = extendTheme({
  config,
  styles: {
    global: {},
  },
  fonts: {
    heading: "Space Grotesk",
    body: "Space Grotesk",
  },
});

export function Providers() {
  const toast = useToast();
  const mutationCache = new MutationCache({
    onError: (error) => {
      if (error instanceof ApiError) {
        const msg = error?.body?.detail
          ?.map((msg: Record<string, string>) => msg.message)
          .join(", ");
        toast({
          title: error.statusText || error.message,
          status: "error",
          description: msg,
        });
      }
    },
  });
  const [queryClient] = useState(
    () =>
      new QueryClient({
        mutationCache,
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
            staleTime: 5000,
            retry: (failureCount, error) => {
              if (error instanceof ApiError) {
                if (error.status === 404) {
                  return false;
                }
              }
              return failureCount < 3;
            },
          },
        },
      }),
  );
  return (
    <ChakraProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <RouterProvider router={router} />
      </QueryClientProvider>
    </ChakraProvider>
  );
}
