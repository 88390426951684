import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Checkbox,
  FormControl,
  FormLabel,
  Spinner,
  Stack,
  Table,
  Tbody,
  Td,
  Tr,
  Text,
  useDisclosure,
} from "@chakra-ui/react";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import EfakturaSvg from "./EfakturaSvg";
import { useUserProfile } from "../../../hooks/useUserProfile";

export default function IssuerModal() {
  const { isLoading, data } = useUserProfile();
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Card width={250}>
        <CardHeader display="flex" justifyContent="center" h={75}>
          <EfakturaSvg width={150} />
        </CardHeader>
        <CardBody>
          <Text>Opprett utstederavtale for efaktura her</Text>
        </CardBody>
        <CardFooter>
          <Button
            onClick={onOpen}
            colorScheme="blue"
            variant="solid"
            isDisabled={true}
          >
            Kommer snart!
          </Button>
        </CardFooter>
      </Card>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Opprett utstederavtale</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack>
              {isLoading && <Spinner />}
              {!isLoading && (
                <>
                  <Table>
                    <Tbody>
                      <Tr>
                        <Td>Organisasjonsnummer</Td>
                        <Td>{data?.orgNumber}</Td>
                      </Tr>
                      <Tr>
                        <Td>Bedriftsnavn</Td>
                        <Td>{data?.companyName}</Td>
                      </Tr>
                      <Tr>
                        <Td>Kontonummer</Td>
                        <Td>
                          {
                            data?.bankAccounts?.filter((x) => x.isPrimary)?.[0]
                              ?.bankAccountNumber
                          }
                        </Td>
                      </Tr>
                      <Tr>
                        <Td>E-post</Td>
                        <Td>{data?.email}</Td>
                      </Tr>
                      <Tr>
                        <Td>By</Td>
                        <Td>{data?.billingCity}</Td>
                      </Tr>
                      <Tr>
                        <Td>Postnummer</Td>
                        <Td>{data?.billingPostalCode}</Td>
                      </Tr>
                    </Tbody>
                  </Table>
                  <Alert status="warning">
                    <Stack>
                      <Stack direction="row">
                        <AlertIcon />
                        <AlertTitle>Eksisterende avtale!</AlertTitle>
                      </Stack>
                      <AlertDescription>
                        Vi fant en utstederavtale på X med status Y
                      </AlertDescription>
                    </Stack>
                  </Alert>
                </>
              )}
            </Stack>
          </ModalBody>

          <ModalFooter>
            <Stack>
              <FormControl>
                <Stack direction="row" alignItems="flex-start">
                  <Checkbox colorScheme="red" size="lg" />
                  <FormLabel>
                    Jeg er sikker på at jeg ønsker å overskrive eksisterende
                    avtale
                  </FormLabel>
                </Stack>
              </FormControl>
              <Stack direction="row">
                <Button colorScheme="red" isDisabled={true}>
                  Overskriv
                </Button>
                <Button colorScheme="blue" mr={3} onClick={onClose}>
                  Lukk
                </Button>
              </Stack>
            </Stack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
