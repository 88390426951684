import {
  Button,
  Card,
  Checkbox,
  FormControl,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  Spinner,
  Stack,
} from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";
import { useState } from "react";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
import { useDebounceState } from "../../../hooks/useDebounceState";
import { useCustomerSearch } from "../../../hooks/useCustomers";
import { useGroupSearch } from "../../../hooks/useGroupSearch";

type SearchCustomers = {
  customerNumbers: number[];
  isInvalid: boolean;
  setCustomers: (arg: number[]) => void;
  groupUuid?: string | null;
  setGroup: (arg?: string | null) => void;
};

export const SearchCustomers = ({
  customerNumbers,
  isInvalid,
  setCustomers,
  groupUuid,
  setGroup,
}: SearchCustomers) => {
  const [searchQuery, updateSearchQuery] = useState("");
  const debouncedSearchQuery = useDebounceState(searchQuery);
  const customerSearch = useCustomerSearch(debouncedSearchQuery, 4);
  const groupSearch = useGroupSearch(debouncedSearchQuery, 4);

  return (
    <Card minW={300} minH={300} p={4}>
      <Stack>
        <Heading size="md">Finn kunde</Heading>
        <Tabs>
          <TabList>
            <Tab>Kunder</Tab>
            <Tab>Grupper</Tab>
          </TabList>

          <TabPanels>
            <TabPanel
              onClick={() => {
                updateSearchQuery("");
              }}
            >
              <FormControl isInvalid={Boolean(isInvalid)}>
                <InputGroup>
                  <Input
                    onChange={(e) => {
                      updateSearchQuery(e.target.value);
                    }}
                    placeholder="Søk"
                  />
                  <InputRightElement>
                    {customerSearch.isLoading && <Spinner />}
                  </InputRightElement>
                </InputGroup>
                <Stack spacing={4} py={4}>
                  {customerSearch.data?.map((data) => (
                    <Checkbox
                      key={data.customerNumber}
                      bgColor="blackAlpha.100"
                      p={4}
                      borderRadius="base"
                      isChecked={customerNumbers?.some(
                        (x) => x === data.customerNumber,
                      )}
                      onChange={(e) => {
                        if (e.target.checked && data.customerNumber) {
                          setCustomers([
                            ...(customerNumbers || []),
                            data.customerNumber,
                          ]);
                        } else {
                          setCustomers([
                            ...customerNumbers.filter(
                              (x) => x !== data.customerNumber,
                            ),
                          ]);
                        }
                      }}
                    >
                      {data.name}-{data.orgNumber}
                    </Checkbox>
                  ))}
                  <Button>
                    <AddIcon />
                  </Button>
                </Stack>
              </FormControl>
            </TabPanel>
            <TabPanel>
              <FormControl isInvalid={Boolean(isInvalid)}>
                <InputGroup>
                  <Input
                    onChange={(e) => {
                      updateSearchQuery(e.target.value);
                    }}
                    placeholder="Søk"
                  />
                  <InputRightElement>
                    {groupSearch.isLoading && <Spinner />}
                  </InputRightElement>
                </InputGroup>
                <Stack spacing={4} py={4}>
                  {groupSearch.data?.map((data) => (
                    <Checkbox
                      key={data.uuid || data.groupName}
                      bgColor="blackAlpha.100"
                      p={4}
                      borderRadius="base"
                      isChecked={data.uuid === groupUuid}
                      onChange={(e) => {
                        if (e.target.checked && data.uuid) {
                          setGroup(data.uuid);
                        } else {
                          setGroup(null);
                        }
                      }}
                    >
                      {data.groupName}: ({data.customers.length})
                    </Checkbox>
                  ))}
                  <Button>
                    <AddIcon />
                  </Button>
                </Stack>
              </FormControl>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Stack>
    </Card>
  );
};
