/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum InvoiceStatus {
  DRAFT = "DRAFT",
  REGISTERED = "REGISTERED",
  PAID = "PAID",
  CANCELLED = "CANCELLED",
}
