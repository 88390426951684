import camelize from "camelize-ts";
import { DefaultService } from "../generated";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useToast } from "@chakra-ui/react";

export const useInvoiceTemplate = (invoiceTemplatePk: number | undefined) => {
  return useQuery({
    queryKey: ["invoiceTemplate", invoiceTemplatePk],
    queryFn: () =>
      DefaultService.fakturInvoicesViewsInvoicesTemplate({
        invoiceTemplatePk: invoiceTemplatePk || 0,
      }),
    select: (data) => ({
      ...camelize(data),
      lines: data.lines.map((line) => ({
        ...camelize(line),
        netUnitAmount: Number(line.net_unit_amount),
      })),
    }),
    enabled: Boolean(invoiceTemplatePk),
  });
};

export const useInvoiceTemplateDelete = () => {
  const toast = useToast();
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ["invoiceTemplateDelete"],
    mutationFn: ({ invoiceTemplatePk }: { invoiceTemplatePk: number }) => {
      return DefaultService.fakturInvoicesViewsInvoicesTemplateDelete({
        invoiceTemplatePk,
      });
    },
    onSuccess: () => {
      toast({ title: "Fakturamal slettet!", status: "success" });
      queryClient.invalidateQueries(["invoiceTemplateList"]);
    },
  });
};
