import {
  Box,
  Heading,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogFooter,
  useDisclosure,
  Button,
  Spinner,
  ButtonGroup,
} from "@chakra-ui/react";

import { InvoiceTemplateForm } from "../InvoiceTemplateForm";
import {
  useInvoiceTemplate,
  useInvoiceTemplateDelete,
} from "../../../../hooks/useInvoiceTemplate";
import { useParams } from "react-router-dom";
import { NotFound } from "../../../../404";
import { useRef } from "react";

export default function InvoiceTemplateEditPage() {
  const { id } = useParams();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef(null);
  const invoiceTemplatePk = parseInt(id || "0", 10);
  const invoiceTemplateQuery = useInvoiceTemplate(invoiceTemplatePk);
  const invoiceTemplateDelete = useInvoiceTemplateDelete();

  if (invoiceTemplateQuery.isLoading) {
    return <Spinner />;
  }

  if (!id || !invoiceTemplateQuery.data) {
    return <NotFound />;
  }

  return (
    <Box>
      <Heading as="h1" mb={4}>
        Endre fakturamal
      </Heading>
      <InvoiceTemplateForm invoiceTemplate={invoiceTemplateQuery.data} />
      <Button colorScheme="red" mt={8} onClick={onOpen}>
        Slett fakturamal
      </Button>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Slette fakturamal
            </AlertDialogHeader>

            <AlertDialogFooter>
              {invoiceTemplateDelete.isLoading && <Spinner />}
              {!invoiceTemplateDelete.isLoading && (
                <ButtonGroup>
                  <Button ref={cancelRef} onClick={onClose}>
                    Avbryt
                  </Button>
                  <Button
                    colorScheme="red"
                    onClick={() => {
                      invoiceTemplateDelete.mutate({ invoiceTemplatePk });
                    }}
                    ml={3}
                  >
                    Slett
                  </Button>
                </ButtonGroup>
              )}
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  );
}
