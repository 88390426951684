import {
  Heading,
  Stat,
  StatLabel,
  StatNumber,
  Button,
  Flex,
  Link,
  TableContainer,
  Table,
  Thead,
  Tr,
  Td,
  Tbody,
  Stack,
  Spinner,
  IconButton,
  ButtonGroup,
} from "@chakra-ui/react";
import { Link as ReactRouterLink } from "react-router-dom";
import { useSession } from "../../hooks/useSession";
import { useCustomerList } from "../../hooks/useCustomers";
import { EditIcon } from "@chakra-ui/icons";

export default function CustomerPage() {
  useSession();

  const { data, isLoading } = useCustomerList();

  return (
    <>
      <Stack>
        <Heading as="h1" mb={4}>
          Kundeliste
        </Heading>
        <Stack direction="row">
          <ButtonGroup>
            <Link as={ReactRouterLink} to="/customers/create/">
              <Button variant="solid" colorScheme="blue">
                Lag ny kunde
              </Button>
            </Link>
            <Link as={ReactRouterLink} to="/customers/groups/">
              <Button variant="solid" colorScheme="purple">
                Kundegrupper
              </Button>
            </Link>
          </ButtonGroup>
        </Stack>
        <Flex alignItems="center">
          <Stat my={4}>
            <StatLabel>Antall kunder</StatLabel>
            <StatNumber>{data?.length || 0}</StatNumber>
          </Stat>
        </Flex>
        {isLoading && <Spinner />}
        <TableContainer>
          <Table variant="striped">
            <Thead>
              <Tr>
                <Td>ID</Td>
                <Td>Navn</Td>
                <Td>E-post</Td>
                <Td>Organisasjonsnummer</Td>
                <Td>Handlinger</Td>
              </Tr>
            </Thead>
            <Tbody>
              {!isLoading &&
                data?.map((customer) => (
                  <Tr key={customer.customerNumber}>
                    <Td>{customer.customerNumber}</Td>
                    <Td>{customer.name}</Td>
                    <Td>{customer.email}</Td>
                    <Td>{customer.orgNumber}</Td>
                    <Td width={100} p={0}>
                      <Stack
                        direction="row"
                        gap={0}
                        p={0}
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Link
                          as={ReactRouterLink}
                          to={`/customers/${customer.customerNumber}/edit/`}
                        >
                          <IconButton
                            size="lg"
                            colorScheme="blue"
                            variant="ghost"
                            icon={<EditIcon />}
                            aria-label="Endre"
                          />
                        </Link>
                      </Stack>
                    </Td>
                  </Tr>
                ))}
            </Tbody>
          </Table>
        </TableContainer>
      </Stack>
    </>
  );
}
