import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  Link,
  Stack,
  Text,
} from "@chakra-ui/react";
import { ArrowForwardIcon } from "@chakra-ui/icons";
import { Link as ReactRouterLink } from "react-router-dom";
import { useSignupNewsletter } from "./hooks/useSignupNewsletter";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const schema = yup
  .object({
    email: yup.string().email().required(),
  })
  .required();

export default function Home() {
  const signupNewsletter = useSignupNewsletter();

  const {
    register,
    handleSubmit,
    formState: { errors: formStateErrors },
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(schema),
  });
  const onSubmit = (data: { email: string }) => {
    return signupNewsletter.mutate(data);
  };
  return (
    <>
      <Box textAlign="center" p={10}>
        <Flex alignItems="center" justifyContent="center">
          <img
            src="/android-chrome-512x512.png"
            width={128}
            height={128}
            alt="logo"
          />
        </Flex>
      </Box>
      <Stack>
        <Text fontSize="xx-large" mb={8} align="center">
          Fakturaer – enkelt. <br />
        </Text>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack>
            <Box display="flex" justifyContent="center">
              <Stack direction="row" alignItems="center">
                <Heading size="md">Hold deg oppdatert!</Heading>
              </Stack>
            </Box>
            <Box display="flex" justifyContent="center">
              <FormControl
                isInvalid={Boolean(formStateErrors["email"])}
                width={400}
              >
                <FormLabel fontSize={12}>
                  Ved å melde deg på godkjenner du vår{" "}
                  <Link
                    as={ReactRouterLink}
                    to="/privacy/"
                    style={{ textDecoration: "underline" }}
                  >
                    personvernserklæring
                  </Link>
                </FormLabel>
                <InputGroup>
                  <Input {...register("email")} placeholder="name@domain.xyz" />
                  <InputRightElement>
                    <Button type="submit">
                      <ArrowForwardIcon fontSize="x-large" />
                    </Button>
                  </InputRightElement>
                </InputGroup>
              </FormControl>
            </Box>
          </Stack>
        </form>
      </Stack>
    </>
  );
}
