import snakify from "snakify-ts";
import { CustomerBodySchema, DefaultService } from "../generated";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Camelize } from "camelize-ts";
import { useToast } from "@chakra-ui/react";

export const useCustomerUpdate = (customerNumber: number | null) => {
  const queryClient = useQueryClient();
  const toast = useToast();
  return useMutation(
    ["customerCreate"],
    (data: Camelize<CustomerBodySchema>) =>
      DefaultService.fakturCustomersViewsCustomerUpdate({
        customerNumber: customerNumber || 0,
        requestBody: snakify(data),
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["customerSearch"]);
        queryClient.invalidateQueries(["customer", customerNumber]);
        toast({ title: "Kunde oppdatert!", status: "success" });
      },
    },
  );
};
