import { useToast } from "@chakra-ui/react";
import { DefaultService } from "../generated";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export const useInvoicesQueue = () => {
  const toast = useToast();
  const queryClient = useQueryClient();
  return useMutation(
    ["invoicesQueue"],
    (invoiceUuid?: string) =>
      DefaultService.fakturInvoicesViewsInvoicesQueue({ invoiceUuid }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["invoiceDetail"]);
        queryClient.invalidateQueries(["invoiceList"]);
        toast({ title: "Fakturaer lagt til utsending", status: "success" });
      },
    },
  );
};
