import camelize from "camelize-ts";
import { DefaultService } from "../generated";
import { useInfiniteQuery } from "@tanstack/react-query";

export const useInvoiceList = () => {
  return useInfiniteQuery({
    queryKey: ["invoiceList"],
    queryFn: (page) => {
      return DefaultService.fakturInvoicesViewsInvoiceList({
        page: page.pageParam,
      });
    },
    select: (data) => ({
      ...data,
      pages: data.pages.map((page) =>
        page.results.map((invoice) => camelize(invoice)),
      ),
    }),
    getNextPageParam: (data) => {
      return data.next;
    },
    getPreviousPageParam: (data) => {
      return data.previous;
    },
  });
};
