import {
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  Input,
  Spinner,
  Stack,
} from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Camelize } from "camelize-ts";
import { useEffect, useState } from "react";
import { CustomerBodySchema, CustomerSchema } from "../../generated";
import { useCustomerCreate } from "../../hooks/useCustomers";
import { useBrreg } from "../../hooks/useBrreg";
import { useCustomerUpdate } from "../../hooks/useCustomerUpdate";

const schema = yup
  .object({
    customerNumber: yup.number().positive().max(9999999), // Max 7 digits
    name: yup.string().required(),
    email: yup.string().email().ensure(),
    orgNumber: yup
      .string()
      .matches(/^(.{9}|)$/, { excludeEmptyString: true })
      .ensure(),
    phoneNumber: yup
      .string()
      .matches(/^(.{8}|)$/, { excludeEmptyString: true })
      .ensure(),
  })
  .required();

export const CustomerForm = ({
  customer,
  setCustomer,
}: {
  customer: Camelize<CustomerSchema> | null;
  setCustomer: (customerNumber: Camelize<CustomerSchema>) => void;
}) => {
  const customerCreateMutation = useCustomerCreate({
    onSuccessCallback: setCustomer,
  });
  const customerUpdateMutation = useCustomerUpdate(
    customer?.customerNumber || null,
  );

  const [enterCustomerNumber, setEnterCustomerNumber] = useState(false);

  const {
    register,
    unregister,
    handleSubmit,
    reset,
    getValues,
    formState: { errors: formStateErrors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    values: {
      customerNumber: customer?.customerNumber,
      name: customer?.name || "",
      email: customer?.email || "",
      orgNumber: customer?.orgNumber || "",
      phoneNumber: customer?.phoneNumber || "",
    },
  });
  const orgNumber = getValues("orgNumber") || "";
  const brreg = useBrreg(orgNumber);

  useEffect(() => {
    const values = getValues();
    reset({
      ...values,
      customerNumber: values?.customerNumber,
      name: values?.name || brreg.data?.navn,
    });
  }, [reset, getValues, brreg.data]);

  useEffect(() => {
    reset({});
  }, [reset, customerCreateMutation.isSuccess]);

  const onSubmit = (data: Camelize<CustomerBodySchema>) => {
    if (customer?.customerNumber) {
      return customerUpdateMutation.mutate(data);
    }
    return customerCreateMutation.mutate(data);
  };
  const isLoading =
    customerCreateMutation.isLoading || customerUpdateMutation.isLoading;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack>
        <Stack direction="row">
          <FormLabel>Manuelt kundenummer</FormLabel>
          <Checkbox
            size="lg"
            onChange={(e) => {
              if (!e.target.checked) {
                unregister("customerNumber");
              }
              setEnterCustomerNumber(e.target.checked);
            }}
          />
        </Stack>
        {enterCustomerNumber && (
          <FormControl
            isInvalid={Boolean(formStateErrors["customerNumber"])}
            minWidth={300}
          >
            <Input
              {...register("customerNumber")}
              placeholder="Kundenummer autogenereres om man ikke legger det inn"
            />
          </FormControl>
        )}
        <FormControl
          isInvalid={Boolean(formStateErrors["orgNumber"])}
          minWidth={300}
        >
          <FormLabel>Organisasjonsnummer</FormLabel>
          <Input {...register("orgNumber")} placeholder="987654321" />
        </FormControl>
        <FormControl
          isInvalid={Boolean(formStateErrors["name"])}
          minWidth={300}
        >
          <FormLabel>Kundenavn</FormLabel>
          <Input {...register("name")} placeholder="Ola Nordmann" />
        </FormControl>
        <FormControl
          isInvalid={Boolean(formStateErrors["email"])}
          minWidth={300}
        >
          <FormLabel>E-post</FormLabel>
          <Input {...register("email")} placeholder="mail@example.com" />
        </FormControl>
        <FormControl
          isInvalid={Boolean(formStateErrors["phoneNumber"])}
          minWidth={300}
        >
          <FormLabel>Mobiltelefon</FormLabel>
          <Input {...register("phoneNumber")} placeholder="999 99 999" />
        </FormControl>
        <Button
          leftIcon={isLoading ? <Spinner /> : <AddIcon />}
          type="submit"
          isDisabled={isLoading}
        >
          Lagre kunde
        </Button>
      </Stack>
    </form>
  );
};
