import { DefaultService, InvoiceTemplateBodySchema } from "../generated";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Camelize } from "camelize-ts";
import snakify from "snakify-ts";
import { useToast } from "@chakra-ui/react";

export const useInvoiceTemplateUpdate = (invoiceTemplatePk?: number) => {
  const toast = useToast();
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ["invoiceTemplateUpdate"],
    mutationFn: (data: Camelize<InvoiceTemplateBodySchema>) => {
      return DefaultService.fakturInvoicesViewsInvoicesTemplateUpdate({
        invoiceTemplatePk: invoiceTemplatePk || 0,
        requestBody: snakify({
          name: data.name,
          deliveryType: data.deliveryType,
          dueDays: data.dueDays,
          lines: data.lines.map((line) => ({
            id: line.id,
            invoicedQuantity: line.invoicedQuantity,
            vatRatePercentage: line.vatRatePercentage,
            description: line.description,
            netUnitAmount: line.netUnitAmount,
          })),
        }),
      });
    },
    onSuccess: () => {
      toast({ title: "Fakturamal oppdatert!", status: "success" });
      queryClient.invalidateQueries(["invoiceTemplateList"]);
    },
  });
};
