import { Box, Button, Heading, Spinner, Stack } from "@chakra-ui/react";
import { Link, useSearchParams } from "react-router-dom";
import { useUnsubscribeEmail } from "../../../hooks/useUnsubscribeEmail";

export default function Unsubscribe() {
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token") || "";

  const mutation = useUnsubscribeEmail(token);

  return (
    <Stack textAlign="center" p={10}>
      <Heading as="h1" mb={4}>
        Avregistrer e-post
      </Heading>
      <Box mt={10} minW={350}>
        {mutation.isLoading && <Spinner />}
        {!mutation.isLoading && token && !mutation.isSuccess && (
          <Button onClick={() => mutation.mutate()} colorScheme="blue">
            Klikk for å avregistrere
          </Button>
        )}
        {(!token || mutation.isSuccess) && (
          <Link to="/">Gå til hovedsiden</Link>
        )}
      </Box>
    </Stack>
  );
}
