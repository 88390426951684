import { DefaultService, LoginSchema } from "../generated";
import { useMutation } from "@tanstack/react-query";
import { Camelize } from "camelize-ts";
import { useNavigate } from "react-router-dom";
import snakify from "snakify-ts";

export const useLoginVerify = () => {
  const navigate = useNavigate();
  return useMutation({
    mutationFn: (data: Camelize<LoginSchema>) =>
      DefaultService.fakturUsersViewsLoginVerify({
        requestBody: snakify(data),
      }),
    onSuccess: () => {
      navigate("/invoices/");
    },
  });
};
