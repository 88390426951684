import camelize from "camelize-ts";
import { DefaultService } from "../generated";
import { useQuery } from "@tanstack/react-query";

export const useInvoiceTemplateList = () => {
  return useQuery({
    queryKey: ["invoiceTemplateList"],
    queryFn: DefaultService.fakturInvoicesViewsInvoicesTemplateList,
    select: (data) => camelize(data),
  });
};
