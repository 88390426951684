import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Select,
  Stack,
} from "@chakra-ui/react";
import { AddIcon, DeleteIcon } from "@chakra-ui/icons";
import { useFieldArray, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { Camelize } from "camelize-ts";

import {
  InvoiceTemplateBodySchema,
  InvoiceTemplateSchema,
} from "../../../generated";
import { useInvoiceTemplateUpdate } from "../../../hooks/useInvoiceTemplateUpdate";
import { useInvoiceTemplateCreate } from "../../../hooks/useInvoiceTemplateCreate";

const linesSchema = yup.object({
  id: yup.number().integer(),
  invoicedQuantity: yup.number().integer().required(),
  description: yup.string().required(),
  netUnitAmount: yup.number().required(),
  vatRatePercentage: yup.number().integer().required(),
});
const schema = yup
  .object({
    name: yup.string().required(),
    dueDays: yup.number().positive().integer().required(),
    lines: yup.array().of(linesSchema).required(),
  })
  .required();

export const InvoiceTemplateForm = ({
  invoiceTemplate,
}: {
  invoiceTemplate?: Camelize<InvoiceTemplateSchema>;
}) => {
  const invoiceCreateMutation = useInvoiceTemplateCreate();
  const invoiceUpdateMutation = useInvoiceTemplateUpdate(invoiceTemplate?.id);

  const {
    register,
    handleSubmit,
    formState: { errors: formStateErrors },
    watch,
    setValue,
    getValues,
    control,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    values: {
      name: invoiceTemplate?.name || "",
      // @ts-ignore
      dueDays: invoiceTemplate?.dueDays || "",
      // @ts-ignore
      lines: invoiceTemplate?.lines || [
        {
          invoicedQuantity: 1,
          description: "",
          // @ts-ignore
          netUnitAmount: undefined,
          vatRatePercentage: 25,
        },
      ],
    },
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "lines",
  });

  watch("lines");

  const onSubmit = (data: Camelize<InvoiceTemplateBodySchema>) => {
    if (invoiceTemplate?.id) {
      return invoiceUpdateMutation.mutate(data);
    }
    return invoiceCreateMutation.mutate(data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack mt={8}>
        <FormLabel>Navn på fakturamal</FormLabel>
        <Input {...register("name")} />
        <FormControl isInvalid={Boolean(formStateErrors["dueDays"])}>
          <FormLabel>Dager forfall</FormLabel>
          <Input {...register("dueDays")} placeholder="14" />
        </FormControl>
      </Stack>
      <Box mt={4}>
        <FormLabel>Fakturalinjer</FormLabel>
        {fields.map((field, i) => {
          const invoiceTemplateLine = getValues("lines")?.[i];
          const errorLine = formStateErrors.lines?.[i];
          return (
            <Stack
              key={field.id}
              margin={4}
              direction="row"
              wrap={["wrap", "wrap", "inherit"]}
            >
              <FormControl
                isInvalid={Boolean(errorLine?.invoicedQuantity)}
                minWidth={100}
                maxWidth={100}
              >
                <FormLabel>Antall</FormLabel>
                <NumberInput
                  defaultValue={1}
                  min={1}
                  onChange={(valueString) => {
                    // Potential bug with deletion here
                    setValue(
                      `lines.${i}.invoicedQuantity`,
                      parseInt(valueString, 10),
                    );
                  }}
                >
                  <NumberInputField
                    {...register(`lines.${i}.invoicedQuantity`)}
                    placeholder="Antall"
                  />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
              </FormControl>
              <FormControl
                isInvalid={Boolean(errorLine?.description)}
                minWidth={300}
                maxWidth={450}
              >
                <FormLabel>Beskrivelse</FormLabel>
                <Input
                  {...register(`lines.${i}.description`)}
                  placeholder="Beskrivelse"
                />
              </FormControl>
              <FormControl
                isInvalid={Boolean(errorLine?.netUnitAmount)}
                minWidth={150}
                maxWidth={150}
              >
                <FormLabel>Enhetspris</FormLabel>
                <NumberInput>
                  <NumberInputField
                    {...register(`lines.${i}.netUnitAmount`)}
                    placeholder="100"
                  />
                </NumberInput>
              </FormControl>
              <FormControl
                isInvalid={Boolean(errorLine?.vatRatePercentage)}
                minWidth={75}
                maxWidth={100}
              >
                <FormLabel>MVA</FormLabel>
                <Select {...register(`lines.${i}.vatRatePercentage`)}>
                  <option value={0}>0%</option>
                  <option value={15}>15%</option>
                  <option value={25}>25%</option>
                </Select>
              </FormControl>
              <FormControl minWidth={100} maxWidth={100}>
                <FormLabel>Sum</FormLabel>
                <Input
                  isDisabled={true}
                  value={(
                    (invoiceTemplateLine?.invoicedQuantity || 0) *
                      (invoiceTemplateLine?.netUnitAmount || 0) *
                      (1 +
                        (invoiceTemplateLine?.vatRatePercentage || 0) / 100) ??
                    0
                  ).toFixed(2)}
                />
              </FormControl>
              <Flex alignItems="flex-end">
                <Button
                  onClick={() => {
                    remove(i);
                  }}
                >
                  <DeleteIcon />
                </Button>
              </Flex>
            </Stack>
          );
        })}
        <Stack>
          <Button
            leftIcon={<AddIcon />}
            onClick={() =>
              append({
                invoicedQuantity: 1,
                description: "",
                // @ts-ignore
                netUnitAmount: undefined,
                vatRatePercentage: 25,
              })
            }
            variant="outline"
          >
            Legg til linje
          </Button>
          <Button type="submit">Lagre</Button>
        </Stack>
      </Box>
    </form>
  );
};
