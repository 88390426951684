const EfakturaSvg = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    id="Logo"
    x={0}
    y={0}
    style={{
      enableBackground: "new 0 0 594 126",
    }}
    viewBox="0 0 594 126"
    {...props}
  >
    <style>{".st0{fill:#000046}"}</style>
    <path
      d="M536.4 114.9c-4.4 0-10.8-.9-16-5.4-5.9-5.1-9-13.6-9-25.1 0-35.6 19.6-48.2 36.4-48.2 8.4 0 15.3 3.3 19.2 5.6l.9-4.1h11.8L571 100c-.2 1.5-.2 2.3-.1 2.6.4.1 1.5.1 3.9-.6l4.6-1.3-1.6 11.7-1.8.6c-2.2.7-4.1.8-5.8.8-3.9 0-6.9-1.2-8.9-3.4-.9-1-1.6-2.2-2-3.6-7.3 5.5-14.6 8.1-22.9 8.1zm34.4-12.2-1.4 1.2 1.4-1.2zm-24.1-54.2c-18 0-22.8 22.7-22.8 36 0 16.3 7.2 18.4 13.5 18.4 6.7 0 12.2-1.8 22.2-11.1l5.1-37.2c-3.9-3-10.8-6.1-18-6.1zM476.1 113.3h-13l.5-3.6 10.1-72h12.7l-.9 6.2c5.7-5 10.9-7.7 18.3-7.7.9 0 2.1 0 3.3.2l3.1.5-2 13.6-3-.3c-7.7-.9-14.1 3.3-21.7 10.3l-7.4 52.8zM406.3 114.9c-7.5 0-13.1-2.1-16.7-6.3-4.1-4.6-5.5-11.8-4.2-21.2l6.9-49.8h13l-6.9 49.8c-.9 6.5-.5 10.7 1.5 12.9 1.4 1.6 3.7 2.4 7.1 2.4 8 0 15.5-3.3 25-11.1l7.4-54h13l-10.7 75.6h-12.4l.8-6c-5.1 3.2-14 7.7-23.8 7.7zM352.3 114.9c-7.4 0-12.5-1.7-15.6-5.3-3.1-3.5-4.1-8.7-3.1-15.9l6.2-44.1H329l1.4-12h11.1l3.7-27.6 13.3-1.9-4 29.5h19.7l-1.7 12h-19.7l-6.3 43.9c-.4 3.3-.7 6.8.5 8.2.5.6 1.9 1.3 5.4 1.3 4.1 0 9-1.7 12-3.3l3.6-2 2.7 11.5-1.9 1.2c-4.4 2.8-11 4.5-16.5 4.5zM312.7 113.3h-13.2L281.9 75l-11.1 9.5-3.9 28.8h-13L268.7 8.2h13l-8.3 59 33.2-29.5H324L291.1 67l21.6 46.3zM196.6 114.9c-4.4 0-10.8-.9-16-5.4-5.9-5.1-9-13.6-9-25.1 0-35.6 19.6-48.2 36.4-48.2 8.4 0 15.3 3.3 19.2 5.6l.9-4.1H240l-8.8 62.3c-.2 1.5-.2 2.3-.1 2.6.4.1 1.5.1 3.9-.6l4.6-1.3-1.6 11.7-1.9.6c-2.2.7-4.1.8-5.8.8-3.9 0-6.9-1.2-8.9-3.4-.9-1-1.6-2.2-2-3.6-7.1 5.5-14.5 8.1-22.8 8.1zM207 48.5c-18 0-22.8 22.7-22.8 36 0 16.3 7.2 18.4 13.5 18.4 6.7 0 12.2-1.8 22.2-11.1l5.1-37.2c-3.9-3-10.9-6.1-18-6.1zM115.3 113.3h-12.7L116.7 8.2h57L172 20h-44l-4.9 35.2h41.4l-1.7 12.3h-41.2l-6.3 45.8zM64.4 88.1c-2.8 7.4-7.9 11.1-15.3 11.1-11.9 0-13.7-8-13.9-15.8v-.7l1.9-.2c7.6-.6 20.3-1.7 30.3-5.9C78.3 72.1 83.6 65 83.6 55c0-7.8-3.5-20.9-27-20.9-26.2 0-43.8 19.1-43.8 47.4 0 12.5 4.2 33.3 32.6 33.3 17.7 0 30.7-8.3 35.7-22.7l1.4-4.1H64.4zM36.2 68.6c1.6-12.4 8.7-20.1 18.6-20.1 7.2 0 8.7 4.1 8.7 7.5 0 5.4-3.4 8.9-10.6 11-6.3 1.9-13.8 2.4-15.9 2.5h-.8v-.9z"
      className="st0"
    />
  </svg>
);
export default EfakturaSvg;
