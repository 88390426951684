import {
  Avatar,
  AvatarBadge,
  Button,
  Container,
  Divider,
  Flex,
  Heading,
  Link,
  Stack,
} from "@chakra-ui/react";
import { useSession } from "../hooks/useSession";
import { Link as ReactRouterLink } from "react-router-dom";

export const Navbar = () => {
  const { data } = useSession({ redirectWhenUnauthorized: false });
  const isLoggedIn = Boolean(data?.email);

  return (
    <Container maxW="100%" p={0}>
      <Container maxW="container.lg" px={8}>
        <Flex justifyContent="space-between" alignItems="center" height="4rem">
          <Link as={ReactRouterLink} to={isLoggedIn ? "/invoices" : "/"}>
            <Heading size="sm">FAKTUR.NO</Heading>
          </Link>
          {isLoggedIn && (
            <Stack direction="row" spacing={4}>
              <Link as={ReactRouterLink} to="/invoices">
                <Heading size="sm">Fakturaer</Heading>
              </Link>
              <Link as={ReactRouterLink} to="/customers">
                <Heading size="sm">Kunder</Heading>
              </Link>
            </Stack>
          )}
          {isLoggedIn && (
            <Link as={ReactRouterLink} to="/profile/">
              <Avatar boxSize={8}>
                <AvatarBadge />
              </Avatar>
            </Link>
          )}
          {!isLoggedIn && (
            <Link as={ReactRouterLink} to="/auth/login">
              <Button>Logg inn</Button>
            </Link>
          )}
        </Flex>
      </Container>
      <Divider />
    </Container>
  );
};
