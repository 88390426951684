import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import snakify from "snakify-ts";
import {
  CustomerBodySchema,
  CustomerSchema,
  DefaultService,
} from "../generated";
import camelize, { Camelize } from "camelize-ts";
import { useToast } from "@chakra-ui/react";

export const useCustomerSearch = (searchQuery: string, size?: number) => {
  return useQuery(
    ["customerSearch", searchQuery],
    () =>
      DefaultService.fakturCustomersViewsCustomerSearch({
        query: searchQuery,
        size,
      }),
    {
      select: (data) => data.map((customer) => camelize(customer)),
    },
  );
};

export const useCustomer = (customerNumber: number | null) => {
  return useQuery(
    ["customer", customerNumber],
    () =>
      DefaultService.fakturCustomersViewsCustomer({
        customerNumber: customerNumber || 0,
      }),
    {
      select: (customer) => camelize(customer),
      enabled: Boolean(customerNumber),
    },
  );
};

export const useCustomerDelete = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ["customerDelete"],
    mutationFn: ({ customerNumber }: { customerNumber: number }) =>
      DefaultService.fakturCustomersViewsCustomerDelete({ customerNumber }),
    onSuccess: () => {
      queryClient.invalidateQueries(["customer"]);
      queryClient.invalidateQueries(["customerSearch"]);
      queryClient.invalidateQueries(["customerList"]);
      navigate("/customers/");
    },
  });
};

export const useCustomerList = () => {
  return useQuery(
    ["customerList"],
    DefaultService.fakturCustomersViewsCustomerList,
    { select: (data) => camelize(data) },
  );
};

export const useCustomerCreate = ({
  onSuccessCallback,
}: {
  onSuccessCallback?: (data: Camelize<CustomerSchema>) => void;
}) => {
  const queryClient = useQueryClient();
  const toast = useToast();
  return useMutation(
    ["customerCreate"],
    (data: Camelize<CustomerBodySchema>) =>
      DefaultService.fakturCustomersViewsCustomerCreate({
        requestBody: snakify(data),
      }),
    {
      onSuccess: (data) => {
        onSuccessCallback && onSuccessCallback(camelize(data));
        queryClient.invalidateQueries(["customerSearch"]);
        queryClient.invalidateQueries(["customerList"]);
        toast({ title: "Kunde opprettet!", status: "success" });
      },
    },
  );
};
