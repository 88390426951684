import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Link,
  Spinner,
  Stack,
  Text,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { useLoginEmail } from "../../../hooks/useLoginEmail";
import {
  Link as ReactRouterLink,
  useNavigate,
  useOutletContext,
} from "react-router-dom";
import { RegistrationSchema } from "../../../generated";
import { useSession } from "../../../hooks/useSession";

const schema = yup
  .object({ email: yup.string().email().required() })
  .required();

export default function LoginPage() {
  const sessionQuery = useSession({
    redirectWhenAuthorized: true,
    redirectWhenUnauthorized: false,
  });
  const [, setEmail] = useOutletContext<[string, (arg: string) => void]>();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors: formStateErrors },
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(schema),
  });

  const mutation = useLoginEmail();

  useEffect(() => {
    if (mutation.status == "success") {
      navigate("/auth/magic-link/");
    }
  }, [navigate, mutation.status]);

  const onSubmit = async (data: RegistrationSchema) => {
    setEmail(data.email);
    return await mutation.mutate({ email: data.email });
  };

  const isDev = process.env.NODE_ENV === "development";

  return (
    <Stack textAlign="center" p={10}>
      <Heading as="h1" mb={4}>
        Logg inn
      </Heading>
      {sessionQuery.isLoading && (
        <Box>
          <Spinner />
        </Box>
      )}
      {!sessionQuery.isLoading && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormControl isInvalid={Boolean(formStateErrors["email"])}>
            <FormLabel>E-post</FormLabel>
            <Input {...register("email")} placeholder="accounting@faktur.no" />
          </FormControl>
          <Box mt={10} minW={350}>
            {mutation.isLoading && <Spinner />}
            {!mutation.isLoading && <Button type="submit">Logg inn</Button>}
          </Box>
        </form>
      )}
      {isDev && (
        <Link as={ReactRouterLink} to="/auth/signup/">
          <Text>Ikke registrert?</Text>
        </Link>
      )}
    </Stack>
  );
}
