import { AddIcon } from "@chakra-ui/icons";
import {
  Box,
  Heading,
  Button,
  Spinner,
  Stack,
  Card,
  FormControl,
  InputGroup,
  Input,
  Checkbox,
  InputRightElement,
  FormLabel,
  Tag,
  TagLabel,
  TagCloseButton,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Camelize } from "camelize-ts";
import { useNavigate } from "react-router-dom";
import { useSession } from "../../../hooks/useSession";
import { useDebounceState } from "../../../hooks/useDebounceState";
import { useCustomerSearch } from "../../../hooks/useCustomers";
import {
  CustomerGroupBodySchema,
  CustomerGroupSchema,
} from "../../../generated";
import {
  useCustomerGroupCreate,
  useCustomerGroupUpdate,
} from "../../../hooks/useCustomerGroup";

const schema = yup
  .object({
    groupName: yup.string().required(),
    customers: yup.array().of(yup.number().positive().required()).required(),
  })
  .required();

export default function CustomerGroupForm({
  group,
}: {
  group?: Camelize<CustomerGroupSchema>;
}) {
  useSession();
  const navigate = useNavigate();

  const [searchQuery, updateSearchQuery] = useState("");
  const debouncedSearchQuery = useDebounceState(searchQuery);
  const customerSearch = useCustomerSearch(debouncedSearchQuery);
  const customerGroupMutation = useCustomerGroupCreate();
  const customerGroupUpdateMutation = useCustomerGroupUpdate(group?.uuid);

  const {
    register,
    handleSubmit,
    formState: { errors: formStateErrors },
    watch,
    setValue,
    getValues,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    values: {
      groupName: group?.groupName || "",
      customers:
        group?.customers.flatMap((customer) => customer.customerNumber) || [],
    },
  });

  watch("customers");
  watch("groupName");

  useEffect(() => {
    if (customerGroupMutation.isSuccess) {
      navigate("/customers/groups/");
    }
  }, [navigate, customerGroupMutation.isSuccess]);

  const customers = getValues("customers") || [];

  const onSubmit = (data: Camelize<CustomerGroupBodySchema>) => {
    if (group?.uuid) {
      return customerGroupUpdateMutation.mutate(data);
    }
    return customerGroupMutation.mutate(data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack>
        <Input {...register("customers")} type="hidden" />
        <Box mt={8}>
          <FormControl isInvalid={Boolean(formStateErrors["groupName"])}>
            <FormLabel>Gruppenavn</FormLabel>
            <Input {...register("groupName")} />
          </FormControl>
        </Box>
        <Stack display="flex" direction="row" gap={8}>
          <Card w={300} minH={300} p={4}>
            <Stack>
              <Heading size="md">Finn kunde</Heading>
              <FormControl isInvalid={Boolean(formStateErrors["customers"])}>
                <InputGroup>
                  <Input
                    onChange={(e) => {
                      updateSearchQuery(e.target.value);
                    }}
                    placeholder="Søk"
                  />
                  <InputRightElement>
                    {customerSearch.isLoading && <Spinner />}
                  </InputRightElement>
                </InputGroup>
                <Stack spacing={4} py={4}>
                  {customerSearch.data?.map((data) => (
                    <Checkbox
                      key={data.customerNumber}
                      bgColor="blackAlpha.100"
                      p={4}
                      borderRadius="base"
                      isChecked={customers?.some(
                        (x) => x === data.customerNumber,
                      )}
                      onChange={(e) => {
                        if (e.target.checked && data.customerNumber) {
                          setValue("customers", [
                            ...(customers || []),
                            data.customerNumber,
                          ]);
                        } else {
                          setValue("customers", [
                            ...customers.filter(
                              (x) => x !== data.customerNumber,
                            ),
                          ]);
                        }
                      }}
                    >
                      {data.name}-{data.orgNumber}
                    </Checkbox>
                  ))}
                  <Button>
                    <AddIcon />
                  </Button>
                </Stack>
              </FormControl>
            </Stack>
          </Card>
          <Stack
            display="flex"
            wrap="wrap"
            alignItems="flex-start"
            justifyContent="flex-start"
          >
            {customers.map((user) => (
              <Tag key={user} fontSize={24} height={12} p={4} borderRadius={8}>
                <TagLabel>Kunde: {user}</TagLabel>
                <TagCloseButton
                  onClick={() => {
                    setValue("customers", [
                      ...customers.filter((x) => x !== user),
                    ]);
                  }}
                />
              </Tag>
            ))}
          </Stack>
        </Stack>
        <Button type="submit">
          Lagre kundegruppe {getValues("groupName")}
        </Button>
      </Stack>
    </form>
  );
}
