import { EmailIcon, ViewIcon } from "@chakra-ui/icons";
import {
  Box,
  Heading,
  Button,
  Flex,
  Link,
  Stack,
  Text,
  Spinner,
  Card,
  CardHeader,
  CardBody,
  StackDivider,
  SimpleGrid,
  Divider,
  Stepper,
  StepIndicator,
  Step,
  StepTitle,
  StepSeparator,
  StepIcon,
  StepStatus,
  StepNumber,
  TableContainer,
  Table,
  Tr,
  Td,
  Tbody,
  Thead,
  Badge,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogFooter,
  useDisclosure,
  Alert,
  AlertIcon,
  ButtonGroup,
} from "@chakra-ui/react";
import { useSession } from "../../../hooks/useSession";

import {
  InvoiceDeliveryType,
  InvoiceStatus,
  OpenAPI,
} from "../../../generated";
import { useParams } from "react-router-dom";
import { NotFound } from "../../../404";
import {
  useInvoiceDelete,
  useInvoiceDetail,
} from "../../../hooks/useInvoiceDetail";
import { getColorScheme } from "../../../utils/colorScheme";
import { getFormattedDate } from "../../../utils/dates";
import { useInvoicesQueue } from "../../../hooks/useInvoicesQueue";
import { useRef } from "react";
import { InvoiceStatusButton } from "./InvoiceStatusButton";

const deliverySteps = [
  {
    status: "PENDING",
    title: "Venter",
    description: "Venter på utsending",
  },
  { status: "QUEUED", title: "Lagt i kø", description: "I kø for å sendes ut" },
  { status: "SENT", title: "Utsendt", description: "Utsendt" },
];

export default function InvoiceDetailPage() {
  useSession();

  const { id } = useParams();
  const { data: invoice, isLoading } = useInvoiceDetail(id || "");
  const { isOpen, onClose } = useDisclosure();
  const cancelRef = useRef(null);

  const invoiceDeleteMutation = useInvoiceDelete();
  const invoicesQueueMutation = useInvoicesQueue();

  if (isLoading) {
    return <Spinner />;
  }

  if (!invoice) {
    return <NotFound />;
  }

  return (
    <Stack>
      <Heading as="h1" mb={4}>
        Faktura {invoice.invoiceNumber}
      </Heading>
      {invoice.status === InvoiceStatus.DRAFT && (
        <Alert status="info">
          <AlertIcon />
          Denne fakturaen er lagret som et utkast. Klikk &quot;Registrer og send
          faktura&quot; for å generere fakturanummer og legge fakturaen til
          utsending.
        </Alert>
      )}
      <Stack direction="row" flexWrap="wrap">
        <ButtonGroup>
          <Button
            onClick={() => invoicesQueueMutation.mutate(invoice.uuid)}
            rightIcon={<EmailIcon />}
            colorScheme="blue"
            isDisabled={invoice.status !== InvoiceStatus.DRAFT}
          >
            Registrer og send faktura
          </Button>
          <Link
            href={`${OpenAPI.BASE}/invoices/${invoice.uuid}/pdf/`}
            target="_blank"
          >
            <Button rightIcon={<ViewIcon />}>
              {invoice.status === InvoiceStatus.DRAFT
                ? "Forhåndsvis PDF"
                : "Vis PDF"}
            </Button>
          </Link>
          <Link href="/invoices/create">
            <Button variant="solid" isDisabled={true}>
              Send purring
            </Button>
          </Link>
          <InvoiceStatusButton invoice={invoice} />
        </ButtonGroup>
        <AlertDialog
          isOpen={isOpen}
          leastDestructiveRef={cancelRef}
          onClose={onClose}
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                Slette faktura
              </AlertDialogHeader>

              <AlertDialogFooter>
                {invoiceDeleteMutation.isLoading && <Spinner />}
                {!invoiceDeleteMutation.isLoading && (
                  <>
                    <Button ref={cancelRef} onClick={onClose}>
                      Avbryt
                    </Button>
                    <Button
                      colorScheme="red"
                      onClick={() => {
                        invoiceDeleteMutation.mutate({
                          invoiceUuid: invoice.uuid,
                        });
                      }}
                      ml={3}
                    >
                      Slett
                    </Button>
                  </>
                )}
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      </Stack>
      <StackDivider />
      {isLoading && (
        <Flex flex={1} justifyContent="center">
          <Spinner />
        </Flex>
      )}
      {!isLoading && (
        <>
          <SimpleGrid
            spacing={4}
            templateColumns="repeat(auto-fill, minmax(400px, 1fr))"
          >
            <Card>
              <CardHeader>
                <Heading size="md">Oppsummering</Heading>
              </CardHeader>

              <CardBody pt={0}>
                <Stack divider={<StackDivider />} spacing="4">
                  <TableContainer>
                    <Table size="sm">
                      <Tbody>
                        <Tr>
                          <Td>Kundens navn</Td>
                          <Td>{invoice.customerName}</Td>
                        </Tr>
                        <Tr>
                          <Td>Organisasjonsnummer</Td>
                          <Td>{invoice.customerIdentifier}</Td>
                        </Tr>
                        <Tr>
                          <Td>KID / Melding</Td>
                          <Td>{invoice.customerIdentificationNumber}</Td>
                        </Tr>
                        <Tr>
                          <Td>Status:</Td>
                          <Td>
                            <Badge
                              colorScheme={getColorScheme(invoice.status)}
                              fontSize={12}
                              p={1}
                              borderRadius={4}
                            >
                              {invoice.status}
                            </Badge>
                          </Td>
                        </Tr>
                        <Tr>
                          <Td>Utsendingsmetode:</Td>
                          <Td>
                            <Badge fontSize={12} p={1} borderRadius={4}>
                              {invoice.deliveryType}
                            </Badge>
                          </Td>
                        </Tr>
                        <Tr>
                          <Td>Betalt dato:</Td>
                          <Td>
                            {invoice.paidDate
                              ? getFormattedDate(invoice.paidDate)
                              : "-"}
                          </Td>
                        </Tr>
                        <Tr>
                          <Td>Fakturadato:</Td>
                          <Td>{invoice.issueDate}</Td>
                        </Tr>
                        <Tr>
                          <Td>Forfallsdato:</Td>
                          <Td>{invoice.dueDate}</Td>
                        </Tr>
                        <Tr>
                          <Td>Kontonummer:</Td>
                          <Td>{invoice.bankAccountNumber}</Td>
                        </Tr>
                      </Tbody>
                    </Table>
                  </TableContainer>
                </Stack>
              </CardBody>
            </Card>
            <Card>
              <CardHeader>
                <Heading size="md">Utsendinger</Heading>
              </CardHeader>
              <CardBody>
                {invoice.deliveries.length === 0 && (
                  <>
                    {invoice.deliveryType === InvoiceDeliveryType.NONE && (
                      <Text>Fakturaen har ingen valgt utsendingsmetode.</Text>
                    )}
                    {invoice.deliveryType !== InvoiceDeliveryType.NONE && (
                      <>
                        <Text>Ingen utsending enda.</Text>
                        <Text>
                          Fakturaen blir utsendt som: {invoice.deliveryType}
                        </Text>
                      </>
                    )}

                    {invoice.deliveryType === InvoiceDeliveryType.EMAIL && (
                      <Stepper index={0} mt={4} mb={4}>
                        {deliverySteps.map((step, index) => (
                          <Step key={index}>
                            <StepIndicator>
                              <StepStatus
                                complete={<StepIcon />}
                                incomplete={<StepNumber />}
                                active={<StepNumber />}
                              />
                            </StepIndicator>

                            <Box flexShrink="0">
                              <StepTitle>{step.title}</StepTitle>
                            </Box>

                            <StepSeparator />
                          </Step>
                        ))}
                      </Stepper>
                    )}
                  </>
                )}
                {invoice.deliveries.map((delivery) => (
                  <Box key={delivery.uuid}>
                    <TableContainer>
                      <Table size="sm">
                        <Tbody>
                          <Tr>
                            <Td>ID</Td>
                            <Td>{delivery.uuid.slice(0, 8)}</Td>
                          </Tr>
                          <Tr>
                            <Td>Form</Td>
                            <Td>{delivery.deliveryType}</Td>
                          </Tr>
                          <Tr>
                            <Td>Sendt tidspunkt</Td>
                            <Td>
                              {delivery.sentTime
                                ? getFormattedDate(delivery.sentTime)
                                : "-"}
                            </Td>
                          </Tr>
                        </Tbody>
                      </Table>
                    </TableContainer>

                    <Stepper
                      index={
                        deliverySteps.findIndex(
                          (step) => step.status == delivery.status,
                        ) + 1
                      }
                      mt={4}
                      mb={4}
                    >
                      {deliverySteps.map((step, index) => (
                        <Step key={index}>
                          <StepIndicator>
                            <StepStatus
                              complete={<StepIcon />}
                              incomplete={<StepNumber />}
                              active={<StepNumber />}
                            />
                          </StepIndicator>

                          <Box flexShrink="0">
                            <StepTitle>{step.title}</StepTitle>
                          </Box>

                          <StepSeparator />
                        </Step>
                      ))}
                    </Stepper>
                    <Divider />
                  </Box>
                ))}
              </CardBody>
            </Card>
          </SimpleGrid>

          <Card>
            <CardHeader>
              <Heading size="md">Fakturalinjer</Heading>
            </CardHeader>

            <CardBody pt={0}>
              <Stack divider={<StackDivider />} spacing="4">
                <TableContainer>
                  <Table size="md">
                    <Thead>
                      <Tr>
                        <Td>Antall</Td>
                        <Td>Beskrivelse</Td>
                        <Td>Beløp</Td>
                        <Td>Sum</Td>
                        <Td>MVA</Td>
                        <Td>Total</Td>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {invoice.lines.map((line) => (
                        <Tr key={line.id}>
                          <Td>{line.invoicedQuantity}</Td>
                          <Td>{line.description}</Td>
                          <Td>{line.netUnitAmount}</Td>
                          <Td>{line.netAmount}</Td>
                          <Td>{line.vatRatePercentage}%</Td>
                          <Td>{line.grossAmount}</Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                </TableContainer>
              </Stack>
            </CardBody>
          </Card>
        </>
      )}
    </Stack>
  );
}
