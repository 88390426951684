import { useNavigate } from "react-router-dom";
import {
  BaseInvoiceBodySchema,
  DefaultService,
  InvoiceBodySchema,
} from "../generated";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Camelize } from "camelize-ts";
import snakify from "snakify-ts";
import { useToast } from "@chakra-ui/react";

export const useInvoiceCreate = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ["invoiceCreate"],
    mutationFn: (data: Camelize<InvoiceBodySchema>) => {
      return DefaultService.fakturInvoicesViewsInvoicesCreate({
        requestBody: snakify(data),
      });
    },
    onSuccess: () => {
      toast({ title: "Faktura opprettet!", status: "success" });
      queryClient.invalidateQueries(["invoiceList"]);
      navigate("/invoices/");
    },
  });
};

export const useInvoiceCreateFromTemplate = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ["invoiceCreateFromTemplate"],
    mutationFn: ({
      templateId,
      data,
    }: {
      templateId: number;
      data: Camelize<BaseInvoiceBodySchema>;
    }) => {
      return DefaultService.fakturInvoicesViewsInvoiceFromTemplateCreate({
        templateId,
        requestBody: snakify(data),
      });
    },
    onSuccess: () => {
      toast({ title: "Faktura opprettet!", status: "success" });
      queryClient.invalidateQueries(["invoiceList"]);
      navigate("/invoices/");
    },
  });
};
