import { useToast } from "@chakra-ui/react";
import { DefaultService } from "../generated";
import { useMutation } from "@tanstack/react-query";

export const useUnsubscribeEmail = (token: string) => {
  const toast = useToast();
  return useMutation({
    mutationKey: ["unsubscribeEmail"],
    mutationFn: () =>
      DefaultService.fakturEmailsViewsUnsubscribeEmail({
        token,
      }),
    onSuccess: () => {
      toast({ title: "E-post er avregistrert!", status: "success" });
    },
  });
};
