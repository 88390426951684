import snakify from "snakify-ts";
import { CustomerGroupBodySchema, DefaultService } from "../generated";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import camelize, { Camelize } from "camelize-ts";
import { useToast } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

export const useCustomerGroup = (groupUuid?: string) => {
  const queryClient = useQueryClient();
  return useQuery({
    queryKey: ["customerGroup", groupUuid],
    queryFn: () =>
      DefaultService.fakturCustomersViewsGroup({ groupUuid: groupUuid || "" }),
    onSuccess: () => {
      queryClient.invalidateQueries(["customerGroupList"]);
    },
    select: (data) => camelize(data),
    enabled: Boolean(groupUuid),
  });
};

export const useCustomerGroupList = () => {
  return useQuery(
    ["customerGroupList"],
    DefaultService.fakturCustomersViewsGroupList,
    { select: (data) => camelize(data) },
  );
};

export const useCustomerGroupCreate = () => {
  const toast = useToast();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  return useMutation(
    ["customerGroupCreate"],
    (data: Camelize<CustomerGroupBodySchema>) =>
      DefaultService.fakturCustomersViewsGroupCreate({
        requestBody: snakify(data),
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["customerGroupList"]);
        toast({ title: "Kundegruppe opprettet!", status: "success" });
        navigate("/customers/groups/");
      },
    },
  );
};

export const useCustomerGroupUpdate = (groupUuid?: string | null) => {
  const toast = useToast();
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ["customerGroupUpdate"],
    mutationFn: (data: Camelize<CustomerGroupBodySchema>) =>
      DefaultService.fakturCustomersViewsGroupUpdate({
        groupUuid: groupUuid || "",
        requestBody: snakify(data),
      }),
    onSuccess: () => {
      queryClient.invalidateQueries(["customerGroupList"]);
      toast({ title: "Kundegruppe oppdatert!", status: "success" });
    },
  });
};

export const useCustomerGroupDelete = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ["customerGroupDelete"],
    mutationFn: ({ groupUuid }: { groupUuid: string }) =>
      DefaultService.fakturCustomersViewsGroupDelete({ groupUuid }),
    onSuccess: () => {
      toast({ title: "Kundegruppe slettet!", status: "success" });
      queryClient.invalidateQueries(["customerGroupList"]);
      queryClient.invalidateQueries(["customerGroup"]);
      navigate("/customers/groups/");
    },
  });
};
