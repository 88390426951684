import {
  Box,
  Button,
  Heading,
  IconButton,
  Link,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { useInvoiceTemplateList } from "../../../hooks/useInvoiceTemplateList";
import { Link as ReactRouterLink } from "react-router-dom";
import { AddIcon, EditIcon } from "@chakra-ui/icons";

const InvoiceTemplates = () => {
  const { data, isLoading } = useInvoiceTemplateList();

  return (
    <>
      <Stack direction="row">
        <Link as={ReactRouterLink} to="/invoices/templates/create/">
          <Button variant="solid" colorScheme="blue" rightIcon={<AddIcon />}>
            Lag ny mal
          </Button>
        </Link>
      </Stack>
      <TableContainer>
        <Table variant="striped">
          <Thead>
            <Tr>
              <Td>Malnummer</Td>
              <Td>Navn</Td>
              <Td>Utsendingstype</Td>
              <Td>Forfallsdager</Td>
              <Td>Handlinger</Td>
            </Tr>
          </Thead>
          <Tbody>
            {!isLoading &&
              data?.map((template) => (
                <Tr key={template.id}>
                  <Td>{template.id}</Td>
                  <Td>{template.name}</Td>
                  <Td>{template.deliveryType}</Td>
                  <Td>{template.dueDays}</Td>
                  <Td>
                    <Link
                      as={ReactRouterLink}
                      to={`/invoices/templates/${template.id}/edit/`}
                    >
                      <IconButton
                        size="lg"
                        colorScheme="blue"
                        variant="ghost"
                        icon={<EditIcon />}
                        aria-label="Endre"
                      />
                    </Link>
                  </Td>
                </Tr>
              ))}
          </Tbody>
        </Table>
      </TableContainer>
    </>
  );
};

export default function InvoiceTemplatesPage() {
  return (
    <Box>
      <Heading as="h1" mb={4}>
        Fakturamaler
      </Heading>
      <InvoiceTemplates />
    </Box>
  );
}
