import { useLocation, useNavigate } from "react-router-dom";

import { ApiError, DefaultService } from "../generated";
import { useQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import camelize from "camelize-ts";

type UseSession = {
  redirectWhenUnauthorized?: boolean;
  redirectWhenAuthorized?: boolean;
};

export const useSession = ({
  redirectWhenUnauthorized = true,
  redirectWhenAuthorized = false,
}: UseSession = {}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const sessionQuery = useQuery({
    queryKey: ["session"],
    queryFn: DefaultService.fakturUsersViewsSession,
    retry: (_, error) => {
      if (error instanceof ApiError && error.status === 401) {
        if (redirectWhenUnauthorized) {
          navigate("/auth/login/");
        }
        return false;
      }
      return true;
    },
    select: (data) => camelize(data),
    enabled: location.pathname !== "/",
    staleTime: 30000,
  });

  useEffect(() => {
    if (
      sessionQuery.error instanceof ApiError &&
      sessionQuery.error.status === 401
    ) {
      if (redirectWhenUnauthorized) {
        navigate("/auth/login/");
      }
    }
  }, [
    sessionQuery.error,
    sessionQuery.data,
    navigate,
    redirectWhenUnauthorized,
  ]);

  useEffect(() => {
    if (redirectWhenAuthorized && sessionQuery.status === "success") {
      navigate("/invoices/");
    }
  }, [sessionQuery.status, redirectWhenAuthorized, navigate]);

  return sessionQuery;
};
