import camelize from "camelize-ts";
import { DefaultService } from "../generated";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useToast } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

export const useInvoiceDetail = (invoiceNumber: string) => {
  return useQuery({
    queryKey: ["invoiceDetail", invoiceNumber],
    queryFn: () =>
      DefaultService.fakturInvoicesViewsInvoiceDetail({
        invoiceStr: invoiceNumber,
      }),
    select: (data) => camelize(data),
  });
};

export const useInvoiceDelete = () => {
  const toast = useToast();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  return useMutation({
    mutationKey: ["invoiceDelete"],
    mutationFn: ({ invoiceUuid }: { invoiceUuid: string }) => {
      return DefaultService.fakturInvoicesViewsInvoiceDelete({
        invoiceStr: invoiceUuid,
      });
    },
    onSuccess: () => {
      toast({ title: "Faktura slettet!", status: "success" });
      queryClient.invalidateQueries(["invoiceDetail"]);
      queryClient.invalidateQueries(["invoiceList"]);
      navigate("/invoices/");
    },
  });
};
