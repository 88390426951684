import { useNavigate } from "react-router-dom";
import { DefaultService, InvoiceTemplateBodySchema } from "../generated";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Camelize } from "camelize-ts";
import snakify from "snakify-ts";
import { useToast } from "@chakra-ui/react";

export const useInvoiceTemplateCreate = () => {
  const queryClient = useQueryClient();
  const toast = useToast();
  const navigate = useNavigate();
  return useMutation({
    mutationKey: ["invoiceTemplateCreate"],
    mutationFn: (data: Camelize<InvoiceTemplateBodySchema>) => {
      return DefaultService.fakturInvoicesViewsInvoicesTemplateCreate({
        requestBody: snakify({
          name: data.name,
          deliveryType: data.deliveryType,
          dueDays: data.dueDays,
          lines: data.lines.map((line) => ({
            invoicedQuantity: line.invoicedQuantity,
            vatRatePercentage: line.vatRatePercentage,
            description: line.description,
            netUnitAmount: line.netUnitAmount,
          })),
        }),
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["invoiceTemplateList"]);
      toast({ title: "Fakturamal opprettet!", status: "success" });
      navigate("/invoices/templates/");
    },
  });
};
