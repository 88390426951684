import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Link,
  Spinner,
  Stack,
  Text,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useRegisterEmail } from "../../../hooks/useRegisterEmail";
import { useEffect } from "react";
import {
  Link as ReactRouterLink,
  useNavigate,
  useOutletContext,
} from "react-router-dom";
import { useSession } from "../../../hooks/useSession";
import { RegistrationSchema } from "../../../generated";
import { NotFound } from "../../../404";

const schema = yup
  .object({ email: yup.string().email().required() })
  .required();

export default function SignupPage() {
  useSession({ redirectWhenAuthorized: true, redirectWhenUnauthorized: false });
  const [, setEmail] = useOutletContext<[string, (arg: string) => void]>();

  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors: formStateErrors },
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(schema),
  });

  const mutation = useRegisterEmail();

  useEffect(() => {
    if (mutation.status == "success") {
      navigate("/auth/magic-link/");
    }
  }, [navigate, mutation.status]);

  const onSubmit = async (data: RegistrationSchema) => {
    setEmail(data.email);
    return await mutation.mutate({ email: data.email });
  };

  const isDev = process.env.NODE_ENV === "development";
  if (!isDev) return <NotFound />;

  return (
    <Stack textAlign="center" p={10}>
      <Heading as="h1" mb={4}>
        Registrer
      </Heading>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormControl isInvalid={Boolean(formStateErrors["email"])}>
          <FormLabel>E-post</FormLabel>
          <Input {...register("email")} placeholder="accounting@faktura" />
        </FormControl>
        <Box mt={10} minW={350}>
          {mutation.isLoading && <Spinner />}
          {!mutation.isLoading && <Button type="submit">Fortsett</Button>}
        </Box>
      </form>
      <Link as={ReactRouterLink} to="/auth/login/">
        <Text>Allerede registrert? Logg inn her!</Text>
      </Link>
    </Stack>
  );
}
