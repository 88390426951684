import { Box, Heading } from "@chakra-ui/react";

import { CustomerForm } from "../CustomerForm";
import { useNavigate } from "react-router-dom";

const CustomerCreateForm = () => {
  const navigate = useNavigate();
  return (
    <CustomerForm
      customer={null}
      setCustomer={() => {
        navigate("/customers/");
      }}
    />
  );
};

export default function CustomerCreatePage() {
  return (
    <Box>
      <Heading as="h1" mb={4}>
        Opprett kunde
      </Heading>
      <CustomerCreateForm />
    </Box>
  );
}
