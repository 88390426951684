import { useToast } from "@chakra-ui/react";
import { DefaultService } from "../generated";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export const useInvoiceSend = () => {
  const toast = useToast();
  const queryClient = useQueryClient();
  return useMutation(
    ["invoiceSend"],
    DefaultService.fakturInvoicesViewsInvoiceSend,
    {
      onSuccess: () => {
        toast({ title: "Fakturaer er lagt til utsending" });
        queryClient.invalidateQueries(["invoiceList"]);
      },
    },
  );
};
