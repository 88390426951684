import { Heading, Text, Stack } from "@chakra-ui/react";

export default function PrivacyPage() {
  return (
    <Stack display="flex" gap={6}>
      <Heading as="h1">Personvernserklæring</Heading>
      <Text>
        Vi forstår viktigheten av personvern og ønsker å være åpne om hvordan vi
        samler inn, bruker og beskytter dine personopplysninger. Denne
        personvernerklæringen forklarer vår praksis når det gjelder innsamling
        og bruk av dine opplysninger.
      </Text>
      <Heading as="h2" size="md">
        Bruksdata
      </Heading>
      <Text>
        Vi samler inn informasjon om din bruk av Faktur, inkludert interaksjoner
        med tjenesten, logger, og annen aktivitet relatert til din konto.
      </Text>
      <Heading as="h2" size="md">
        Innhold
      </Heading>
      <Text>
        Innhold du oppretter, lagrer eller sender via våre tjenester, inkludert
        filer, meldinger og annen kommunikasjon, blir lagret på våre servere for
        å kunne tilby tjenesten.
      </Text>
      <Heading as="h2" size="md">
        Hvordan vi bruker din informasjon
      </Heading>
      <Text>
        Vi bruker informasjonen vi samler inn for følgende formål:
        <ul>
          <li>For å gi deg tilgang til og administrere Faktur.</li>
          <li>
            For å kommunisere med deg, inkludert å sende viktige meldinger og
            oppdateringer om tjenesten.
          </li>
          <li>
            For å forbedre vår tjeneste, inkludert feilsøking og utvikling av
            nye funksjoner.
          </li>
          <li>
            For å overholde juridiske forpliktelser og håndheve våre vilkår og
            betingelser.
          </li>
          <li>
            For å beskytte sikkerheten og integriteten til vår tjeneste og dine
            data.
          </li>
        </ul>
      </Text>
      <Heading as="h2" size="md">
        Sikkerhet
      </Heading>
      <Text>
        Vi tar personvern og datasikkerhet på alvor. Vi implementerer passende
        sikkerhetstiltak for å beskytte dine opplysninger mot uautorisert
        tilgang, avsløring, endring eller ødeleggelse. Vi bruker bransjestandard
        metoder for sikker overføring og lagring av data.
      </Text>
      <Heading as="h2" size="md">
        Deling av informasjon
      </Heading>
      <Text>
        Vi deler ikke dine personopplysninger med tredjeparter uten ditt
        samtykke, med mindre det er nødvendig for å oppfylle juridiske
        forpliktelser, for å levere våre tjenester, for å foreta feilsøking,
        eller for å støtte driften av vårt nettsted og tjenester. Vi krever at
        alle slike tredjeparter inngår en personvernsavtale med oss for å sikre
        at dine personopplysninger behandles på en sikker og konfidensiell måte.
        Dette kan inkludere samarbeid med følgende tredjeparter:
        <ul>
          <li>Sentry: Tjeneste for feilsøking</li>
          <li>Amazon Web Services: Tjeneste for e-post</li>
          <li>Heroku: Servertjenester</li>
          <li>Cloudflare: Servertjenester</li>
        </ul>
      </Text>
      <Heading as="h2" size="md">
        Dine rettigheter
      </Heading>
      <Text>
        Du har rettigheter knyttet til dine personopplysninger, inkludert retten
        til å få tilgang til, korrigere eller slette informasjonen. Du kan når
        som helst kontakte oss for å utøve disse rettighetene.
      </Text>
      <Heading as="h2" size="md">
        Endringer
      </Heading>
      <Text>
        Vi forbeholder oss retten til å oppdatere eller endre denne
        personvernerklæringen fra tid til annen. Eventuelle endringer vil bli
        publisert her på nettsiden.
      </Text>
    </Stack>
  );
}
