import { ThemingProps } from "@chakra-ui/react";
import { InvoiceDeliveryStatus, InvoiceStatus } from "../generated";

export const getColorScheme = (
  status: InvoiceStatus | InvoiceDeliveryStatus,
): ThemingProps["colorScheme"] => {
  if (status === InvoiceStatus.CANCELLED) {
    return "gray";
  }
  if (status === InvoiceStatus.PAID) {
    return "green";
  }
  if (status === InvoiceStatus.REGISTERED) {
    return "blue";
  }
  if (status === InvoiceDeliveryStatus.FAILED) {
    return "red";
  }
  if (status === InvoiceDeliveryStatus.QUEUED) {
    return "purple";
  }
  if (status === InvoiceDeliveryStatus.SENT) {
    return "green";
  }
};
