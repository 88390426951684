/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseInvoiceBodySchema } from "../models/BaseInvoiceBodySchema";
import type { CustomerBodySchema } from "../models/CustomerBodySchema";
import type { CustomerGroupBodySchema } from "../models/CustomerGroupBodySchema";
import type { CustomerGroupSchema } from "../models/CustomerGroupSchema";
import type { CustomerSchema } from "../models/CustomerSchema";
import type { InvoiceBodySchema } from "../models/InvoiceBodySchema";
import type { InvoiceDetailSchema } from "../models/InvoiceDetailSchema";
import type { InvoiceSchema } from "../models/InvoiceSchema";
import type { InvoiceTemplateBodySchema } from "../models/InvoiceTemplateBodySchema";
import type { InvoiceTemplateSchema } from "../models/InvoiceTemplateSchema";
import type { InvoiceUpdateBodySchema } from "../models/InvoiceUpdateBodySchema";
import type { LoginSchema } from "../models/LoginSchema";
import type { PaginatedResponseSchema_InvoiceSchema_ } from "../models/PaginatedResponseSchema_InvoiceSchema_";
import type { RegistrationSchema } from "../models/RegistrationSchema";
import type { UserProfileSchema } from "../models/UserProfileSchema";
import type { UserSchema } from "../models/UserSchema";
import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";
export class DefaultService {
  /**
   * Register Email
   * @returns any OK
   * @throws ApiError
   */
  public static fakturUsersViewsRegisterEmail({
    requestBody,
  }: {
    requestBody: RegistrationSchema;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/api/users/register/email/",
      body: requestBody,
      mediaType: "application/json",
    });
  }
  /**
   * Login Email
   * @returns any OK
   * @throws ApiError
   */
  public static fakturUsersViewsLoginEmail({
    requestBody,
  }: {
    requestBody: RegistrationSchema;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/api/users/login/email/",
      body: requestBody,
      mediaType: "application/json",
    });
  }
  /**
   * Login Verify
   * @returns any OK
   * @throws ApiError
   */
  public static fakturUsersViewsLoginVerify({
    requestBody,
  }: {
    requestBody: LoginSchema;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/api/users/login/verify/",
      body: requestBody,
      mediaType: "application/json",
    });
  }
  /**
   * Session
   * @returns UserSchema OK
   * @throws ApiError
   */
  public static fakturUsersViewsSession(): CancelablePromise<UserSchema> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/users/session/",
    });
  }
  /**
   * Logout Api
   * @returns any OK
   * @throws ApiError
   */
  public static fakturUsersViewsLogoutApi(): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/users/logout/",
    });
  }
  /**
   * Update User Api
   * @returns UserProfileSchema OK
   * @throws ApiError
   */
  public static fakturUsersViewsUpdateUserApi({
    requestBody,
  }: {
    requestBody: UserProfileSchema;
  }): CancelablePromise<UserProfileSchema> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/api/users/update/",
      body: requestBody,
      mediaType: "application/json",
    });
  }
  /**
   * User Profile Api
   * @returns UserProfileSchema OK
   * @throws ApiError
   */
  public static fakturUsersViewsUserProfileApi(): CancelablePromise<UserProfileSchema> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/users/profile/",
    });
  }
  /**
   * Get Invoice Efaktura
   * @returns any OK
   * @throws ApiError
   */
  public static fakturInvoicesViewsGetInvoiceEfaktura({
    pk,
  }: {
    pk: number;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/invoices/{pk}/efaktura/",
      path: {
        pk: pk,
      },
    });
  }
  /**
   * Invoice List
   * @returns PaginatedResponseSchema_InvoiceSchema_ OK
   * @throws ApiError
   */
  public static fakturInvoicesViewsInvoiceList({
    page = 1,
  }: {
    page?: number;
  }): CancelablePromise<PaginatedResponseSchema_InvoiceSchema_> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/invoices/",
      query: {
        page: page,
      },
    });
  }
  /**
   * Invoices Create
   * @returns InvoiceSchema OK
   * @throws ApiError
   */
  public static fakturInvoicesViewsInvoicesCreate({
    requestBody,
  }: {
    requestBody: InvoiceBodySchema;
  }): CancelablePromise<Array<InvoiceSchema>> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/api/invoices/create/",
      body: requestBody,
      mediaType: "application/json",
    });
  }
  /**
   * Invoice From Template Create
   * @returns InvoiceSchema OK
   * @throws ApiError
   */
  public static fakturInvoicesViewsInvoiceFromTemplateCreate({
    templateId,
    requestBody,
  }: {
    templateId: number;
    requestBody: BaseInvoiceBodySchema;
  }): CancelablePromise<Array<InvoiceSchema>> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/api/invoices/template/{template_id}/create-invoice/",
      path: {
        template_id: templateId,
      },
      body: requestBody,
      mediaType: "application/json",
    });
  }
  /**
   * Invoices Queue
   * @returns number OK
   * @throws ApiError
   */
  public static fakturInvoicesViewsInvoicesQueue({
    invoiceUuid,
  }: {
    invoiceUuid?: string | null;
  }): CancelablePromise<Array<number>> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/api/invoices/queue/",
      query: {
        invoice_uuid: invoiceUuid,
      },
    });
  }
  /**
   * Invoice Send
   * @returns any OK
   * @throws ApiError
   */
  public static fakturInvoicesViewsInvoiceSend(): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/api/invoices/send/",
    });
  }
  /**
   * Invoice Update
   * @returns InvoiceSchema OK
   * @throws ApiError
   */
  public static fakturInvoicesViewsInvoiceUpdate({
    invoiceUuid,
    requestBody,
  }: {
    invoiceUuid: string;
    requestBody: InvoiceUpdateBodySchema;
  }): CancelablePromise<InvoiceSchema> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/api/invoices/{invoice_uuid}/update/",
      path: {
        invoice_uuid: invoiceUuid,
      },
      body: requestBody,
      mediaType: "application/json",
    });
  }
  /**
   * Invoices Template List
   * @returns InvoiceTemplateSchema OK
   * @throws ApiError
   */
  public static fakturInvoicesViewsInvoicesTemplateList(): CancelablePromise<
    Array<InvoiceTemplateSchema>
  > {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/invoices/template/",
    });
  }
  /**
   * Invoices Template Create
   * @returns InvoiceTemplateSchema OK
   * @throws ApiError
   */
  public static fakturInvoicesViewsInvoicesTemplateCreate({
    requestBody,
  }: {
    requestBody: InvoiceTemplateBodySchema;
  }): CancelablePromise<InvoiceTemplateSchema> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/api/invoices/template/create/",
      body: requestBody,
      mediaType: "application/json",
    });
  }
  /**
   * Invoices Template Update
   * @returns InvoiceTemplateSchema OK
   * @throws ApiError
   */
  public static fakturInvoicesViewsInvoicesTemplateUpdate({
    invoiceTemplatePk,
    requestBody,
  }: {
    invoiceTemplatePk: number;
    requestBody: InvoiceTemplateBodySchema;
  }): CancelablePromise<InvoiceTemplateSchema> {
    return __request(OpenAPI, {
      method: "PUT",
      url: "/api/invoices/template/{invoice_template_pk}/update/",
      path: {
        invoice_template_pk: invoiceTemplatePk,
      },
      body: requestBody,
      mediaType: "application/json",
    });
  }
  /**
   * Invoices Template
   * @returns InvoiceTemplateSchema OK
   * @throws ApiError
   */
  public static fakturInvoicesViewsInvoicesTemplate({
    invoiceTemplatePk,
  }: {
    invoiceTemplatePk: number;
  }): CancelablePromise<InvoiceTemplateSchema> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/invoices/template/{invoice_template_pk}/",
      path: {
        invoice_template_pk: invoiceTemplatePk,
      },
    });
  }
  /**
   * Invoices Template Delete
   * @returns any OK
   * @throws ApiError
   */
  public static fakturInvoicesViewsInvoicesTemplateDelete({
    invoiceTemplatePk,
  }: {
    invoiceTemplatePk: number;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: "/api/invoices/template/{invoice_template_pk}/",
      path: {
        invoice_template_pk: invoiceTemplatePk,
      },
    });
  }
  /**
   * Invoice Detail
   * @returns InvoiceDetailSchema OK
   * @throws ApiError
   */
  public static fakturInvoicesViewsInvoiceDetail({
    invoiceStr,
  }: {
    invoiceStr: string;
  }): CancelablePromise<InvoiceDetailSchema> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/invoices/{invoice_str}/",
      path: {
        invoice_str: invoiceStr,
      },
    });
  }
  /**
   * Invoice Delete
   * @returns any OK
   * @throws ApiError
   */
  public static fakturInvoicesViewsInvoiceDelete({
    invoiceStr,
  }: {
    invoiceStr: string;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: "/api/invoices/{invoice_str}/",
      path: {
        invoice_str: invoiceStr,
      },
    });
  }
  /**
   * Customer List
   * @returns CustomerSchema OK
   * @throws ApiError
   */
  public static fakturCustomersViewsCustomerList(): CancelablePromise<
    Array<CustomerSchema>
  > {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/customers/",
    });
  }
  /**
   * Customer Search
   * @returns CustomerSchema OK
   * @throws ApiError
   */
  public static fakturCustomersViewsCustomerSearch({
    query,
    size = 10,
  }: {
    query: string;
    size?: number;
  }): CancelablePromise<Array<CustomerSchema>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/customers/search/",
      query: {
        query: query,
        size: size,
      },
    });
  }
  /**
   * Customer Create
   * @returns CustomerSchema OK
   * @throws ApiError
   */
  public static fakturCustomersViewsCustomerCreate({
    requestBody,
  }: {
    requestBody: CustomerBodySchema;
  }): CancelablePromise<CustomerSchema> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/api/customers/create/",
      body: requestBody,
      mediaType: "application/json",
    });
  }
  /**
   * Customer Update
   * @returns CustomerSchema OK
   * @throws ApiError
   */
  public static fakturCustomersViewsCustomerUpdate({
    customerNumber,
    requestBody,
  }: {
    customerNumber: number;
    requestBody: CustomerBodySchema;
  }): CancelablePromise<CustomerSchema> {
    return __request(OpenAPI, {
      method: "PUT",
      url: "/api/customers/{customer_number}/update/",
      path: {
        customer_number: customerNumber,
      },
      body: requestBody,
      mediaType: "application/json",
    });
  }
  /**
   * Customer Delete
   * @returns any OK
   * @throws ApiError
   */
  public static fakturCustomersViewsCustomerDelete({
    customerNumber,
  }: {
    customerNumber: number;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: "/api/customers/{customer_number}/",
      path: {
        customer_number: customerNumber,
      },
    });
  }
  /**
   * Customer
   * @returns CustomerSchema OK
   * @throws ApiError
   */
  public static fakturCustomersViewsCustomer({
    customerNumber,
  }: {
    customerNumber: number;
  }): CancelablePromise<CustomerSchema> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/customers/{customer_number}/",
      path: {
        customer_number: customerNumber,
      },
    });
  }
  /**
   * Group List
   * @returns CustomerGroupSchema OK
   * @throws ApiError
   */
  public static fakturCustomersViewsGroupList(): CancelablePromise<
    Array<CustomerGroupSchema>
  > {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/customers/groups/",
    });
  }
  /**
   * Group Create
   * @returns CustomerGroupSchema OK
   * @throws ApiError
   */
  public static fakturCustomersViewsGroupCreate({
    requestBody,
  }: {
    requestBody: CustomerGroupBodySchema;
  }): CancelablePromise<CustomerGroupSchema> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/api/customers/groups/create/",
      body: requestBody,
      mediaType: "application/json",
    });
  }
  /**
   * Group Search
   * @returns CustomerGroupSchema OK
   * @throws ApiError
   */
  public static fakturCustomersViewsGroupSearch({
    query,
    size = 10,
  }: {
    query: string;
    size?: number;
  }): CancelablePromise<Array<CustomerGroupSchema>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/customers/groups/search/",
      query: {
        query: query,
        size: size,
      },
    });
  }
  /**
   * Group Delete
   * @returns any OK
   * @throws ApiError
   */
  public static fakturCustomersViewsGroupDelete({
    groupUuid,
  }: {
    groupUuid: string;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: "/api/customers/groups/{group_uuid}/",
      path: {
        group_uuid: groupUuid,
      },
    });
  }
  /**
   * Group
   * @returns CustomerGroupSchema OK
   * @throws ApiError
   */
  public static fakturCustomersViewsGroup({
    groupUuid,
  }: {
    groupUuid: string;
  }): CancelablePromise<CustomerGroupSchema> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/customers/groups/{group_uuid}/",
      path: {
        group_uuid: groupUuid,
      },
    });
  }
  /**
   * Group Update
   * @returns CustomerGroupSchema OK
   * @throws ApiError
   */
  public static fakturCustomersViewsGroupUpdate({
    groupUuid,
    requestBody,
  }: {
    groupUuid: string;
    requestBody: CustomerGroupBodySchema;
  }): CancelablePromise<CustomerGroupSchema> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/api/customers/groups/{group_uuid}/update/",
      path: {
        group_uuid: groupUuid,
      },
      body: requestBody,
      mediaType: "application/json",
    });
  }
  /**
   * Sign Up For Newsletter
   * @returns any OK
   * @throws ApiError
   */
  public static fakturNewsletterViewsSignUpForNewsletter({
    email,
  }: {
    email: string;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/api/newsletter/signup/",
      query: {
        email: email,
      },
    });
  }
  /**
   * Unsubscribe Newsletter
   * @returns any OK
   * @throws ApiError
   */
  public static fakturNewsletterViewsUnsubscribeNewsletter({
    token,
  }: {
    token: string;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/api/newsletter/unsubscribe/",
      query: {
        token: token,
      },
    });
  }
  /**
   * Bounces
   * @returns any OK
   * @throws ApiError
   */
  public static fakturEmailsViewsBounces(): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/api/emails/bounces/",
    });
  }
  /**
   * Complaints
   * @returns any OK
   * @throws ApiError
   */
  public static fakturEmailsViewsComplaints(): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/api/emails/complaints/",
    });
  }
  /**
   * Unsubscribe Email
   * @returns any OK
   * @throws ApiError
   */
  public static fakturEmailsViewsUnsubscribeEmail({
    token,
  }: {
    token: string;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/api/emails/unsubscribe/",
      query: {
        token: token,
      },
    });
  }
}
