import { useToast } from "@chakra-ui/react";
import { DefaultService } from "../generated";
import { useMutation } from "@tanstack/react-query";

export const useSignupNewsletter = () => {
  const toast = useToast();
  return useMutation({
    mutationKey: ["signupNewsletter"],
    mutationFn: (data: { email: string }) =>
      DefaultService.fakturNewsletterViewsSignUpForNewsletter({
        email: data.email,
      }),
    onSuccess: () => {
      toast({ title: "E-post er meldt på!", status: "success" });
    },
  });
};
