import { Heading, Stat, StatLabel, StatNumber, Stack } from "@chakra-ui/react";
import { useCustomerList } from "../../../../hooks/useCustomers";
import { useSession } from "../../../../hooks/useSession";

import CustomerGroupForm from "../CustomerGroupForm";

export default function CustomerGroupCreatePage() {
  useSession();

  const { data } = useCustomerList();

  return (
    <Stack>
      <Heading as="h1" mb={4}>
        Lag kundegruppe
      </Heading>
      <Stack>
        <Stat>
          <StatLabel>Antall kunder</StatLabel>
          <StatNumber>{data?.length || 0}</StatNumber>
        </Stat>
      </Stack>
      <CustomerGroupForm />
    </Stack>
  );
}
