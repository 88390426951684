import { useQuery } from "@tanstack/react-query";

type Brreg = {
  _embedded: {
    underenheter: {
      navn: string;
      beliggenhetsadresse: {
        adresse: string[];
        poststed: string;
        postnummer: string;
      };
    }[];
  };
};

export const useBrreg = (orgNumber: string) => {
  return useQuery<Brreg, unknown, Brreg["_embedded"]["underenheter"][0]>(
    ["brreg", orgNumber],
    async () => {
      const data = await fetch(
        `https://data.brreg.no/enhetsregisteret/api/underenheter?overordnetEnhet=${orgNumber}`,
      );
      return await data.json();
    },
    {
      enabled: orgNumber.length === 9,
      staleTime: Infinity,
      cacheTime: Infinity,
      select: (data) => data?._embedded?.underenheter?.[0],
    },
  );
};
