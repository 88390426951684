import camelize from "camelize-ts";
import { DefaultService } from "../generated";
import { useQuery } from "@tanstack/react-query";

export const useGroupSearch = (searchQuery: string, size?: number) => {
  return useQuery(
    ["groupSearch", searchQuery],
    () =>
      DefaultService.fakturCustomersViewsGroupSearch({
        query: searchQuery,
        size,
      }),
    {
      select: (data) => data.map((group) => camelize(group)),
    },
  );
};
