import { CheckCircleIcon, WarningIcon } from "@chakra-ui/icons";
import { Button } from "@chakra-ui/react";

import { useInvoiceUpdate } from "../../../hooks/useInvoiceUpdate";
import { InvoiceDetailSchema, InvoiceStatus } from "../../../generated";
import { Camelize } from "camelize-ts";

export const InvoiceStatusButton = ({
  invoice,
}: {
  invoice: Camelize<InvoiceDetailSchema>;
}) => {
  const invoiceUpdateMutation = useInvoiceUpdate();

  if (invoice.status === InvoiceStatus.PAID) {
    return (
      <Button
        width={150}
        onClick={() =>
          invoiceUpdateMutation.mutate({
            invoiceUuid: invoice.uuid,
            requestBody: {
              status: InvoiceStatus.REGISTERED,
            },
          })
        }
        rightIcon={<WarningIcon />}
        colorScheme="yellow"
      >
        Ikke betalt
      </Button>
    );
  }

  return (
    <Button
      width={150}
      onClick={() =>
        invoiceUpdateMutation.mutate({
          invoiceUuid: invoice.uuid,
          requestBody: {
            status: InvoiceStatus.PAID,
          },
        })
      }
      rightIcon={<CheckCircleIcon />}
      colorScheme="green"
      isDisabled={invoice.status !== InvoiceStatus.REGISTERED}
    >
      Sett til betalt
    </Button>
  );
};
